import React from "react";
import { useState, useEffect } from "react";
import PageTitle from "../common/breadcrumb";
import Avater from './image_avater.jpg';
import axios from 'axios';
import { serverLink } from "../../resources/url";
import { currencyConverter } from "../util/generalfunction";
import { formatDateAndTime } from "../util/dateformat";
import ReportTable from "../common/table";


const KYCDetails = (props) => {
    const [isLoading, setIsloading] = useState(true)
    const [details, setDetails] = useState([]);
    const act_no = window.location.href.split("/")[5];
    const [transactions, setTransactions] = useState([]);
    const [susiciousTransactions, setSuspiciousTransactions] = useState([]);
    const columns = ["sn","Transaction ID", "source", "destination", "amount", "date"];
    const [data, setData] = useState([])
    const [account, setAccount] = useState({
        tierperc: "",
        tiercolor: "",
        tier: ""
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}kyc/customer-details/${act_no}`)
                .then((result) => {
                    const response = result.data.recordset;
                    if (response.length > 0) {
                        setDetails(response);
                        const act_tier = response[0].account_tier;
                        if (act_tier !== null || act_tier !== "" || act_tier !== "null") {
                            let tier_ = act_tier === "TIER 1" ? 25 : act_tier === "TIER 2" ? 75 : 100;
                            let tier_color = act_tier === "TIER 1" ? "success" : act_tier === "TIER 2" ? "warning" : "danger";
                            let msg = act_tier;
                            setAccount({
                                ...account,
                                tierperc: tier_,
                                tiercolor: tier_color,
                                tier: msg
                            })
                        }
                    }
                }).catch(() => {
                    console.log('error getting account information')
                })

            await axios.get(`${serverLink}kyc/transaction-details/${act_no}`)
                .then((result) => {
                    let response = result.data.recordset;
                    let rows =[];
                    if (response.length > 0) {
                        setTransactions(response);
                        response.map((x,i)=>{
                            rows.push([
                                i+1,
                                x.t_trans_number, 
                                x.t_account_number,
                                x.t_dest_account_number,
                                x.t_amount_local,
                                x.t_date

                            ])
                        })
                        setData(rows)
                    }
                })

            await axios.get(`${serverLink}kyc/suspicious-transaction-details/${act_no}`)
                .then((result) => {
                    let response = result.data.recordset;
                    let rows =[];
                    if (response.length > 0) {
                        setSuspiciousTransactions(response);
                    }
                    setIsloading(false)
                })

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                            <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
                                <div className="card mb-5">
                                    <div className="card-header pt-5 pb-5">
                                        <PageTitle title={["KYC", "KYC", "Customers Details"]} />
                                    </div>
                                    <div className="card mb-6">
                                        <div className="card-body pt-9 pb-0">
                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                <div className="me-7 mb-4">
                                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                        <img src={Avater} alt="image" />
                                                        <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
                                                    </div>
                                                </div>
                                                {
                                                    details.length > 0 &&
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                            <div className="d-flex flex-column">
                                                                <div className="d-flex align-items-center mb-2">
                                                                    <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{details[0].account_name}</a>
                                                                    <a href="#">
                                                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                                                <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor" />
                                                                                <path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                    <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                                        <span className="svg-icon svg-icon-4 me-1">
                                                                            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                                                                                <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor" />
                                                                                <rect x={7} y={6} width={4} height={4} rx={2} fill="currentColor" />
                                                                            </svg>
                                                                        </span>
                                                                        {details[0].person_occupation}</a>
                                                                    <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                                        <span className="svg-icon svg-icon-4 me-1">
                                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor" />
                                                                                <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor" />
                                                                            </svg>
                                                                        </span>
                                                                        {details[0].person_address}</a>
                                                                    <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                                        <span className="svg-icon svg-icon-4 me-1">
                                                                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                                                                                <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                                                                            </svg>
                                                                        </span>
                                                                        {details[0].entity_phone_number}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-wrap flex-stack">
                                                            <div className="d-flex flex-column flex-grow-1 pe-8">
                                                                <div className="d-flex flex-wrap">
                                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{currencyConverter(details[0].account_balance)}</div>
                                                                        </div>
                                                                        <div className="fw-semibold fs-6 text-gray-400">Current Balance</div>
                                                                    </div>
                                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={80} data-kt-initialized={1}>
                                                                                {transactions.length}</div>
                                                                        </div>
                                                                        <div className="fw-semibold fs-6 text-gray-400">Total Transactions</div>
                                                                    </div>
                                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={60} data-kt-countup-prefix="%" data-kt-initialized={1}>{susiciousTransactions.length}</div>
                                                                        </div>
                                                                        <div className="fw-semibold fs-6 text-gray-400">Suspicious Transactions</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                account.tier !== "" &&
                                                                <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                                                    <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                                        <span className="fw-semibold fs-6 text-gray-400">
                                                                            Account Tier Level : <strong className="fw-bolder">{account.tier}</strong>
                                                                        </span>
                                                                        <span className="fw-bold fs-6">{account.tierperc}%</span>
                                                                    </div>
                                                                    <div className="h-5px mx-3 w-100 bg-light mb-3">
                                                                        <div className={`bg-${account.tiercolor} rounded h-5px`} role="progressbar" style={{ width: `${account.tierperc}%` }} aria-valuenow={account.tierperc} aria-valuemin={0} aria-valuemax={100} />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {/*end::Progress*/}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                                <li className="nav-item mt-2">
                                                    <a className="nav-link text-active-primary ms-0 me-10 py-5 active" data-bs-toggle="tab" href="#overview">Overview</a>
                                                </li>
                                                <li className="nav-item mt-2">
                                                    <a className="nav-link text-active-primary ms-0 me-10 py-5" data-bs-toggle="tab" href="#activities">Activities</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div
                                        className="tab-content" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: false}" data-kt-scroll-height="auto" data-kt-scroll-offset="70px">
                                        <div className="tab-pane fade active show" id="overview" >
                                            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                <div className="card-header cursor-pointer">
                                                    <div className="card-title m-0">
                                                        <h3 className="fw-bold m-0">Account Details</h3>
                                                    </div>
                                                </div>
                                                {
                                                    details.length > 0 &&
                                                    <>
                                                <div className="card-body p-9">
                                                    <div className="row col-md-12 text-start" style={{ fontSize: "16px" }}>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Account Opening Date</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].account_opened_date.split("T")[0]}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Account Number</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {act_no}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Account Status</div>
                                                                    <span className={details[0].account_status_code==="A"? "badge badge-success":"badge badge-danger"}>
                                                                       {
                                                                        details[0].account_status_code==="A"? "Active":"Inactive"
                                                                       }
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Account Branch</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].account_branch}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Account Currency</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].account_currency_code}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Account Type</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].account_type === "E"?"Individual":"Corporate"}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="card-header cursor-pointer">
                                                    <div className="card-title m-0">
                                                        <h3 className="fw-bold m-0">Account Holder Information</h3>
                                                    </div>
                                                </div>
                                                <div className="card-body p-9">
                                                    <div className="row col-md-12 text-start" style={{ fontSize: "16px" }}>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Title</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_title}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>First Name</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_first_name}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                            <label className="fw-semibolder">
                                                                    <div>Last Name</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_last_name}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Date of Birth</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {formatDateAndTime(details[0].person_birth_date, "date")}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Gender</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_gender}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Nationality</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_nationality}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>State</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_state}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>City</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_city}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Address</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_address}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>ID Type</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_id_type}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>ID Number</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_id_number}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Issued By</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].issued_by}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Occupation</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_occupation}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Email Address</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_email}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Passport Number</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_passport_number}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row mb-7">
                                                                <label className="fw-semibolder">
                                                                    <div>Passport Country</div>
                                                                    <span className="fw-bolder fs-6 text-gray-800">
                                                                       {details[0].person_passport_country}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

</>

                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="activities" >
                                        <ReportTable columns={columns} data={data} height="700px" />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default KYCDetails;