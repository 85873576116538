import React, { Component } from 'react';
import { variables } from '../Variables.jsx';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { formatDateAndTime } from '../util/dateformat.jsx';


class Strtransactionsinvolved extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generatedstrs: [],
            modalTitle: "",
            Ind_Name: "",
            id: 0
        }

    }


    componentDidMount() {
        //this.refreshList();

        const params = new URLSearchParams(window.location.search);
        const strcode = params.get('str');
        const reason = params.get('reason');
        const accountno = params.get('accno');
        //alert( accountno + reason);
        document.getElementById('strdesrp').innerHTML = strcode + ' - ' + reason;



        var data = {};
        const url = "http://50.59.99.200/enairaAPI/api/tr_STRsbyCode_account_no?str_code=" + strcode + "&account_no=" + accountno;
        axios.get(url)
            .then(res => {
                // var info = res.data;
                // var object = info[0]; //console.log(object);
                // console.log(object.account_name);
                this.setState({ generatedstrs: res.data });

            })
            .catch(error => {
                console.log(error);
                this.setState({ authError: true, isLoading: false });
            });

    }



    render() {
        const {
            generatedstrs,
            modalTitle,
            id,
            Ind_Name
        } = this.state;

        return (
            <div>

                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid">

                            <div className="col-lg-12">

                                <div className="card">
                                    <div className="card-header pt-7">
                                        <div className="card-title align-items-start flex-column">

                                            <h4>List of Suspicious Transactions Involved</h4>
                                            <h4 id="strdesrp" ></h4>
                                            <small>Click on the Transaction No to view Details of the Transaction</small>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <table id="tbl1" className="table table-bordered table-striped  table-row-dashed align-start h5 gs-0 gy-3 my-0" >

                                            <thead className="bg-dark text-white padding-md " >
                                                <tr>
                                                    <th>Transaction Number</th>
                                                    <th>Source Acct No.</th>
                                                    <th>Dest Acct No.</th>
                                                    <th>Transaction Date</th>
                                                    <th>Location</th>
                                                    <th>Amount Local</th>
                                                    <th>Amount Foriegn</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {generatedstrs.map(dep =>
                                                    <tr key={dep.t_trans_number}>
                                                        <td><Link to={`/strdetails?str=${dep.str_code}&accno=${dep.t_account_number}&transno=${dep.t_trans_number}`}>{dep.t_trans_number}</Link></td>

                                                        <td>{dep.t_source_account_number}</td>
                                                        <td>{dep.t_dest_account_number}</td>
                                                        <td>{formatDateAndTime(dep.t_date, "date")}</td>
                                                        <td>{dep.t_location}</td>
                                                        <td>{dep.t_amount_local}</td>
                                                        <td>{dep.t_dest_foreign_amount}</td>

                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                    </div>


                                </div></div></div></div>
                </div>
            </div>
        )
    }
}
export default Strtransactionsinvolved;