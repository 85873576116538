import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { serverLink } from "../../../resources/url";
import PageTitle from "../../common/breadcrumb";
import { showAlert } from "../../util/generalfunction";
import { connect } from "react-redux";
import { formatDateAndTime } from "../../util/dateformat";
import Loader from "../../util/loader";
import PrivlegeGroups from './groups'
import PrivlegeGroupsMembers from './group-members'

const UserGroups = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormLoading, setisFormLoading] = useState('off')
    const [GroupList, setGroupList] = useState([]);
    const [GroupsData, setGroupsData] = useState([]);
    const [groupMembersList, setGroupMembersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [createGroup, setcreateGroup] = useState({
        PrivilegeDescription: "",
        PrivilegeStatus: "",
        EffectiveDate: "",
        UserID: "",
        GroupID: "",
        InsertedBy: props.loginDetails[0].UserName,
    })

    const getMembers = async () => {
        await axios.get(`${serverLink}user-management/get-all-users`).then((result) => {
            let response = result.data.recordset;
            if (response.length > 0) {
                setUsersList(response);
            }
        })
    }

    const RemovePrivilege = (val) => {
        axios.delete(`${serverLink}user-management/group-members/delete/${val.SN}`)
            .then((res) => {
                if (res.data.message === "success") {
                    setisFormLoading('off');
                    document.getElementById("closeModal").click();
                    getData();
                    showAlert("Success", "Menu created successfully", "success");
                    Reset();
                } else {
                    setisFormLoading('off');
                    getData();
                }
            })
    }

    const getData = () => {
        try {
            axios.get(`${serverLink}user-management/group-members/list`).then((result) => {
                let response = result.data.recordset;
                let rows = [];
                if (response.length > 0) {
                    response.map((item, index) => {
                        rows.push([
                            index + 1,
                            item.UserID,
                            item.GroupID,
                            formatDateAndTime(item.InsertedDate, "date"),
                            item.InsertedBy,
                            (
                                <button type="submit" className="btn btn-sm btn-danger" onClick={() => {
                                    RemovePrivilege(item)
                                }}>
                                    <i className="fa fa-trash fa-sm" />
                                </button>
                            )
                        ])
                    })
                    setGroupMembersList(rows)
                } else {
                    setGroupMembersList([])
                }
            })

            axios.get(`${serverLink}user-management/privilege-groups/list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    console.log(response)
                    setGroupList(response);
                    let rows = [];
                    response.map((item, index) => {
                        rows.push([
                            item.SN,
                            item.PrivilegeDescription,
                            (<label className={item.PrivilegeStatus === "active" ? "badge badge-success" : "badge badge-danger"}>
                                {item.PrivilegeStatus}
                            </label>),
                            formatDateAndTime(item.EffectiveDate, "date"),
                            item.InsertedBy,
                            formatDateAndTime(item.InsertedDate, "date"),
                            <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_new_target"
                            >
                                <i className="fa fa-pen" />
                            </button>
                        ])
                    })
                    setGroupsData(rows)
                } else {
                    setGroupsData([])
                }
            })
        } catch (e) {
            console.log('NETWROK ERROR')
        }
    }

    const onEdit = (e) => {
        setcreateGroup({
            ...createGroup,
            [e.target.name]: e.target.value
        })
    }

    const onGroupSubmit = async (e) => {
        e.preventDefault();
        try {
            if (createGroup.PrivilegeDescription === "") {
                showAlert("Error", "Please enter main menu name", "error")
                return;
            }
            setisFormLoading('on');
            await axios.post(`${serverLink}user-management/privilege-groups/add`, createGroup)
                .then((result => {
                    if (result.data.message === "success") {
                        setisFormLoading('off');
                        document.getElementById("closeModal").click();
                        getData();
                        showAlert("Success", "Menu created successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off');
                        showAlert("EXIST", "Privilege group already exists", "error")
                    }
                }))
        } catch (e) {
            console.log("Network error")
        }
    }

    const onGroupMemberSubmit = async (e) => {
        e.preventDefault();
        if (createGroup.UserID === "" || createGroup.GroupID === "") {
            showAlert("ERROR", "Please select member AND group", "error");
            return;
        }
        setisFormLoading('on')
        try {
            await axios.post(`${serverLink}user-management/privilege-groups/add-group-member`, createGroup).then((result) => {
                if (result.data.message === "success") {
                    setisFormLoading('off');
                    getData();
                    showAlert("SUCCESS", "User added to group successfully", "success");
                } else {
                    setisFormLoading('off');
                    showAlert('ERROR', "Already exists", "error")
                }
            })
        } catch (e) {

        }
    }


    const Reset = () => {
        setcreateGroup({
            ...createGroup,
            PrivilegeDescription: "",
            PrivilegeStatus: "",
            EffectiveDate: "",
            UserID: "",
            GroupID: "",
        })
    }

    useEffect(() => {
        getData();
        getMembers();
    }, [])


    return isLoading ? (<Loader />) : (
        <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-header pt-5">
                            <PageTitle title={["Manage Users", "Users", "User groups"]} />
                            <div className="card-toolbar">
                                <div className="text-center">
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                <div className="flex-grow-1">
                                    <div className="d-flex flex-wrap flex-stack">
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            <div className="d-flex flex-wrap">
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M2 21V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H3C2.4 22 2 21.6 2 21Z" fill="currentColor"></path>
                                                                <path d="M2 10V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H3C2.4 11 2 10.6 2 10Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1} style={{ fontSize: '40px' }}>{GroupList.length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">Privilege Groups</div>
                                                </div>
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor"></rect>
                                                                <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={75} data-kt-initialized={1}>{groupMembersList.length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">Group Members</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                <li className="nav-item mt-2">
                                    <a className="nav-link text-active-primary ms-0 me-10 py-5 active" data-bs-toggle="tab" href="#groups">Groups</a>
                                </li>
                                <li className="nav-item mt-2">
                                    <a className="nav-link text-active-primary ms-0 me-10 py-5" data-bs-toggle="tab" href="#group-members">Group Members</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="tab-content" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: false}" data-kt-scroll-height="auto" data-kt-scroll-offset="70px">
                        <div className="tab-pane fade active show" id="groups" >
                            <PrivlegeGroups isFormLoading={isFormLoading} createGroup={createGroup} onEdit={onEdit} onGroupSubmit={onGroupSubmit} Reset={Reset} GroupsData={GroupsData} />
                        </div>
                        <div className="tab-pane fade" id="group-members" >
                            <PrivlegeGroupsMembers isFormLoading={isFormLoading} createGroup={createGroup} onEdit={onEdit} onGroupMemberSubmit={onGroupMemberSubmit} groupMembersList={groupMembersList} usersList={usersList} GroupList={GroupList} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(UserGroups);
