import React, { Component } from "react";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { encryptData, showAlert } from "../util/generalfunction";
import { setLoginDetails, setPermissionDetails } from "../../actions/softamlactions";
import { connect } from "react-redux";
import Logo from '../../images/e-naira-logo.png'
import { useEffect } from "react";
import { useState } from "react";
import { resolvePath, useNavigate } from "react-router";
import Loader from "../util/loader";
import swal from "sweetalert";
import cbnlogo from "../../images/cbnlogo.png"


const Login = (props) => {
    const [isloading, setisloading] = useState(false);
    const [isFormLoading, setisFormLoading] = useState('off');
    const [locationData, setLocationData] = useState({});
    const [showForm, setShowForm] = useState(true);
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        username: "",
        password: ""
    })

    useEffect(() => {
        getLocationData();
    }, [])

    const getLocationData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        //console.log(res.data)
        setLocationData(res.data);
    }

    const onLoginClicked = (e) => {
        e.preventDefault();
        //setisloading(true);
        setisFormLoading('on');
        axios.get(`${serverLink}login/permission/${userData.username}`)
            .then((result) => {
                let response = result.data.recordset;
                //let response = result.data.fields;
                props.setOnPermissionData(response);
                console.log(result.data.fields);
                
            }).then(async()=>{
                axios.get(`${serverLink}login/staff-login/${userData.username}/${userData.password}`)
                .then((result) => {
                    const response = result.data.recordset;
                   console.log(result.data.recordset);
                      if (response.length > 0) {
                        setShowForm(false);
                        props.setOnLoginDetails(response);
                        setisFormLoading('off');
                        window.sessionStorage.setItem("isLoggedIn", "1");
                        setisloading(false)
                        
                        window.location.href = '/dashboard';
                        //showSuccessAlert("SUCCESS", "Login Successful  ", "success")
    
                   } else {
                        setisloading(false)
                        setisFormLoading('off');
                        showAlert('wrong', 'wrong credentials', 'error')
                    } 
                })
            })

        


    }

    const onEdit = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    };
    const showSuccessAlert=(title, msg, type)=>{
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
                window.location.href = '/dashboard';
        });
    }

    return isloading ? (
        <Loader />
    ) : (
        <>
        <div className="">
        {/* lbody */}
            {showForm &&
                <div className="d-flex flex-column flex-root">
                      {/* <div >
                        <img src={cbnlogo} className="img-fluid pt-5" />
                        <h3 className="h3text">Central Bank of Nigeria (CBN) <br/> Anti-Money Laundaring Solution</h3>
                      </div>  */}
                    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
                    >
                        <div className="d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-5">
                            <div className="w-lg-400px bg-body rounded shadow-sm p-5 p-lg-15 mx-auto">
                                <form className="form w-100" onSubmit={onLoginClicked}>
                                    <div className="mb-10">
                                         {/* <h1 className="text-dark mb-3 text-center ">[CAML 1.0] Login </h1> */}
                                    </div>
                                    <div className="fv-row mb-10" style={{ textAlign: 'left' }}>

                                        <input
                                            type="text"
                                            className="form-control form-control-solid  mb-5"
                                            name="username"
                                            id="username"
                                            onChange={onEdit}
                                            value={userData.username}
                                            placeholder="Username"

                                        />
                                    </div>
                                    <div className="fv-row mb-10" style={{ textAlign: 'left' }}>
                                        {/*<label className="required fs-5 fw-bold mb-2">Password</label>*/}
                                        <input
                                            type="password"
                                            className="form-control form-control-solid  mb-5"
                                            name="password"
                                            id="password"
                                            onChange={onEdit}
                                            value={userData.password}
                                            placeholder="Password"

                                        />
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={isFormLoading} className="btn btn-success w-100 mb-9 mt-9">
                                            <span className="indicator-label">LOGIN</span>
                                            <span className="indicator-progress">Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                            </span>
                                        </button>
                                </div>
                                </form>
                            </div>
                        </div>
                        <div className="d-flex flex-center flex-column-auto ">
                            <div className="d-flex align-items-center fw-bold fs-6">
                                <a href="#" className="text-muted text-hover-primary px-2">Forgot your password?</a>
                                <a href="#"
                                    className="text-muted text-hover-primary px-2">About Us</a>
                                <a href="#" className="text-muted text-hover-primary px-2">Contact Us</a>
                            </div>
                        </div>
                        {/* <div   className="text-muted text-hover-primary px-2"><strong>&copy; <a href="http://cbn.gov.ng/" target="_blank">CBN Anti-Money Laundaring Solution</a></strong></div> */}

                    </div>
                </div>
            }
            </div>
        </>
    )
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionData: (p) => {
            dispatch(setPermissionDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(Login);