import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import ReportTable from "../common/table.jsx";
import { formatDateAndTime } from "../util/dateformat.jsx";
import { showAlert, showConfirm } from "../util/generalfunction.jsx";
import { serverLink } from "../../resources/url";
import Loader from "../util/loader";


const Userlogs = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setisFormLoading] = useState('off');
  const [generatedstrs, setgeneratedstrs] = useState([])
  const columns = ["sn","Log ID","Login Name", "Computer Name", "Login Time", "Logout Time", "Logout Mode"];
  const [data, setData] = useState([]);
  const [allSTRreport, setAllSTRreport] = useState([]);
  const [strByDate, setstrByDate] = useState([]);
  const [strByWalletID, setstrByWalletID] = useState([]);
  
  const [formData, setFormData] = useState({
    start:"",
    end:"",
    wallet_id:""
  })

  const refreshList = async () => {
    try {
  await axios.get(`${serverLink}user-management/get-kycts`).then((result) => {
    let response = result.data.recordset;
    if (response.length > 0) {
        let rows = [];
        response.map((item, index) => {
            rows.push([
                index + 1,
                item.LogID,
                item.LoginName,
                item.ComputerName,
                item.DateTimeLogin,
                item.DateTimeLogout,
                item.LougoutMode
                                                                                                                                   ])
        })
        setData(rows)
        setIsLoading(false)
    } else {
        setData([]);
    }
    })
    } catch (e) {
    console.log('NETWORK ERROR')
    }
  }
  
  useEffect(() => {
    refreshList();
  }, [])

  const onEdit=(e)=>{
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

 
  return isLoading ? (<Loader />) : (
    <div>
      <div className="container-fluid">
        <div className="card ">
          <div className="card-header pt-3 pb-3">
            <div className="card-title align-items-start flex-column">
              <h2>System Administration Audit Trail</h2>
              <small id="strdesrp"></small>
            </div>
          </div>
        </div>
      </div><br/>

      <form >
        <div className="container-fluid">
        
          <div className="card">
          <ReportTable columns={columns} data={data} />
          </div>
        </div>
        <br />
      </form>
    </div>
  );

}
export default Userlogs;
