import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import ReportTable from "../common/table.jsx";
import { formatDateAndTime } from "../util/dateformat.jsx";
import { showAlert, showConfirm } from "../util/generalfunction.jsx";
import { serverLink } from "../../resources/url";
import Loader from "../util/loader";


const Riskrating_medium = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setisFormLoading] = useState('off');
  const [generatedstrs, setgeneratedstrs] = useState([])
  const columns = ["sn","Account No", "Name", "scores", "Risk Rate", "Reason"];
  const [data, setData] = useState([]);
  const [allSTRreport, setAllSTRreport] = useState([]);
  const [strByDate, setstrByDate] = useState([]);
  const [strByWalletID, setstrByWalletID] = useState([]);
  
  const [formData, setFormData] = useState({
    start:"",
    end:"",
    wallet_id:""
  })

  const refreshList = async () => {
    try {
  await axios.get(`${serverLink}riskrating/get-riskratings_medium`).then((result) => {
    let response = result.data.recordset;
    if (response.length > 0) {
        let rows = [];
        response.map((item, index) => {
            rows.push([
                index + 1,
                (<a target={'_blank'} href={`riskratingdetails?acctid=${item.customer_id__c}`}>{item.customer_id__c} </a>),
                item.name,
                item.account_branch,
                (<span className={item.risk_rate === "HIGH RISK" ? "badge badge-danger": item.risk_rate === "MEDIUM RISK"? "badge badge-info":"badge badge-success"}>
                  {item.risk_rate}
                </span>),
                item.reason
            ])
        })
        setData(rows)
        setIsLoading(false)
    } else {
        setData([]);
    }
    })
    } catch (e) {
    console.log('NETWORK ERROR')
    }
  }
  
  useEffect(() => {
    refreshList();
  }, [])

  const onEdit=(e)=>{
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

 
  return isLoading ? (<Loader />) : (
    <div>
      <div className="container-fluid">
        <div className="card ">
          <div className="card-header pt-3 pb-3">
            <div className="card-title align-items-start flex-column">
              <h2>List of Medium Risk Customers</h2>
              <small id="strdesrp">Click on the Account No. to view profile details</small>
            </div>
          </div>
        </div>
      </div><br/>

      <form >
        <div className="container-fluid">
        
          <div className="card">
          <ReportTable columns={columns} data={data} />
          </div>
        </div>
        <br />
      </form>
    </div>
  );

}
export default Riskrating_medium;
