import React from 'react';
import { Oval } from  'react-loader-spinner'
const Loader = ()=>{

    return(
        <div style={{margin: 'auto', position: 'relative', justifyContent:'center', alignItems: 'center', display: 'flex' , height: '400px', opacity:'0.5'}}>
        <Oval
            type="Oval"
            color="#eeeeee"
            height={50}
            width={50}
            secondaryColor="teal"
            timeout={15000} //3 secs
        />
    </div> 
    )
}

export default Loader;