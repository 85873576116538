import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { serverLink } from "../../resources/url";
import PageTitle from "../common/breadcrumb";
import Modal from "../common/modal";
import ReportTable from "../common/table";
import { formatDateAndTime } from "../util/dateformat";
import { showAlert } from "../util/generalfunction";
import Loader from "../util/loader";

const UserRoles = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormLoading, setisFormLoading] = useState('off');
    const columns = ["sn", "Role ID", "Description", "Status", "Effective Date", "Inserted By", "action"];
    const [data, setData] = useState([]);
    const [createRole, setcreateRole] = useState({
        SN: "",
        RoleID: "",
        RoleDescription: "",
        Status: "",
        EffectiveDate: "",
        InsertedBy: props.loginDetails[0].UserName,
    })

    const getRoles = async () => {
        try {
            await axios.get(`${serverLink}user-management/get-roles`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    let rows = [];
                    response.map((item, index) => {
                        rows.push([
                            index + 1,
                            item.RoleID,
                            item.RoleDescription,
                            item.Status,
                            formatDateAndTime(item.EffectiveDate, "date"),
                            item.InsertedBy,
                            <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_new_target"
                                onClick={() => {
                                    setcreateRole({
                                        ...createRole,
                                        SN: item.SN,
                                        RoleID: item.RoleID,
                                        RoleDescription: item.RoleDescription,
                                        Status: item.Status,
                                        EffectiveDate: item.EffectiveDate.split("T")[0],
                                    });
                                }}
                            >
                                <i className="fa fa-pen" />

                            </button>
                        ])
                    })
                    setData(rows)
                    setIsLoading(false)
                } else {
                    setData([]);
                }
            })
        } catch (e) {
            console.log('NETWORK ERROR')
        }

    }

    const onEdit = (e) => {
        setcreateRole({
            ...createRole,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(createRole.RoleID === "" || createRole.RoleDescription === "" || createRole.EffectiveDate === "" || createRole.Status === ""){
            showAlert("ERROR", "Please fill all fields", "error");
            return;
        }
        try {
            if (createRole.SN === "") {
                setisFormLoading("on")
                axios.post(`${serverLink}user-management/add-roles`, createRole).then((result) => {
                    if (result.data.message === 'success') {
                        getRoles();
                        setisFormLoading("off")
                        document.getElementById("closeModal").click();
                        showAlert("SUCCESS", "Roles added successfully", "success");
                    }else{
                        setisFormLoading("off")
                        showAlert("EXIST", "Role already exists", "error")
                    }
                })
            } else {
                setisFormLoading("on");
                axios.put(`${serverLink}user-management/update-roles`, createRole).then((result) => {
                    if (result.data.message === 'success') {
                        getRoles();
                        setisFormLoading("off")
                        document.getElementById("closeModal").click();
                        showAlert("SUCCESS", "Role updated successfully", "success");
                        
                    }
                })
            }
        } catch (e) {

        }
    }

    useEffect(() => {
      getRoles();
    }, [])



    return isLoading ?
        (<Loader />) : (
            <>
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    <div className="d-flex flex-column flex-column-fluid">
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-fluid">
                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                    <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
                                        <div className="card card-flush h-lg-100">
                                            <div className="card-header pt-5">
                                                <PageTitle title={["Manage Users", "Users", "Add/Edit roles"]} />
                                                <div className="card-toolbar">
                                                    <div className="text-center">
                                                        <a href="#" className="btn btn-sm btn-dark fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => {
                                                            setcreateRole({
                                                                ...createRole,
                                                                SN: "",
                                                                RoleID: "",
                                                                RoleDescription: "",
                                                                Status: "",
                                                                EffectiveDate: "",
                                                            })
                                                        }}>Add Role</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body pt-5">
                                                <ReportTable columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Modal type="modal-md" title="Manage roles" subtitle="Create and update user roles"   >
                                    <form id="kt_modal_new_target_form" >
                                        <div className="row g-9 mb-8">
                                            <div className="col-md-12">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Role ID</label>
                                                    <input type="text" className="form-control" placeholder="role id" name="RoleID" onChange={onEdit} value={createRole.RoleID} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Description</label>
                                                    <input type="text" className="form-control" placeholder="Role Description" name="RoleDescription" onChange={onEdit} value={createRole.RoleDescription} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Effective Date</label>
                                                    <input type="date" className="form-control" name="EffectiveDate" onChange={onEdit} value={createRole.EffectiveDate} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Status</label>
                                                    <select onChange={onEdit} value={createRole.Status} className="form-select form-select-solid" name="Status" >
                                                        <option value="" >Select UserType...</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" id="kt_modal_new_address_submit" onClick={onSubmit} data-kt-indicator={isFormLoading} className="btn btn-primary w-150 mb-9 mt-9">
                                                <span className="indicator-label">{
                                                    createRole.SN === "" ? "Submit" : "Update User"
                                                }</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>

                                        </div>
                                        <div />
                                    </form>
                                </Modal>

                            </div>

                        </div>
                    </div>

                </div>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default  connect(mapStateToProps, null)(UserRoles);

