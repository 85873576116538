import axios from "axios";
import { Action } from "history";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { serverLink } from "../../resources/url";
import PageTitle from "../common/breadcrumb";
import ReportTable from "../common/table";
import addpermission from "../permission/addpermission";
import { showAlert } from "../util/generalfunction";
import Loader from "../util/loader";


const Prvileges = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setisFormLoading] = useState('off');
    const [SubSubMenuList, setSubSubMenuList] = useState([]);
    const [GroupList, setGroupList] = useState([]);
    const columns = ["SN", "Main Menu", "Sub Menu", "Sub SubMenu", "Page Link", "visibility", "status", "action"];
    const [data, setData] = useState([]);
    const [PermissionList, setPermissionList] = useState([]);
    const [formData, setFormData] = useState({
        GroupID: "",
        InsertedBy: props.loginDetails[0].UserName,
    })

    const [menus, setMenus] = useState([]);

    const getData = async (group_id = '') => {
        try {
            await axios.get(`${serverLink}user-management/privilege-groups/active/list`)
                .then((result) => {
                    let response = result.data.recordset;
                    if (response.length > 0) {
                        setGroupList(response)
                    }
                })
            getMenus();
            await axios.get(`${serverLink}user-management/privileges/menus-view`)
                .then((result) => {
                    let response = result.data.recordset;
                    if (response.length > 0) {
                        setPermissionList(response)
                    }
                    setIsLoading(false)
                })

                
                if (group_id !== "") {
                    await populatePermission(group_id)
                }
        } catch (e) {
            console.log('NETWORK ERROR')
        }


    }

    const getMenus=async(group_id='')=>{
        await axios.get(`${serverLink}user-management/privileges/group-privileges-menu`)
                .then((result) => {
                   let rows = [];
                    let response = result.data.recordset;
                    if (response.length > 0) {
                        rows = response;
                        setMenus(response)
                        populatePermission(group_id, rows)
                    }
                })
    }
    const onEdit = async (e) => {
        populatePermission(e.target.value, menus)
        setFormData({
            ...formData,
            GroupID: e.target.value
        })
    }

    const populatePermission = async (group_id, menusList='') => {
                let group_menus = menusList.filter(x=>parseInt(x.GroupID) === parseInt(group_id));
                let rows = [];
                console.log(group_menus)
                if (PermissionList.length > 0) {
                    PermissionList.map((item, index) => {
                        let action = group_menus.length > 0 && group_menus.filter(x => parseInt(x.SubSubMenuID) === parseInt(item.SubSubMenuID));
                        if (group_menus.length > 0) {
                            rows.push([
                                index + 1,
                                item.MenuName,
                                item.SubMenuName,
                                item.SubSubMenuName,
                                item.SubSubMenuLink,
                                item.Visibility,
                                action.length > 0 ? <span className="badge badge-success">Granted</span>
                                    : <span className="badge badge-danger">Denied</span>,
                                <span className="d-flex justify-content-center">
                                    <button type="submit" className="btn btn-sm btn-danger" onClick={() => { RemovePermission(item.SubSubMenuID, group_id) }}>
                                        <i className="fa fa-trash" />
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-sm btn-success" onClick={() => { AddPermission(item.SubSubMenuID, group_id) }}>
                                        <i className="fa fa-check" />
                                    </button>
                                </span>

                            ])
                        }

                    })
                    setData(rows);
                }
    }

    const RemovePermission = (val, gID) => {
        try {
            axios.delete(`${serverLink}user-management/privilege/remove/${gID}/${val}`)
                .then((res) => {
                    if (res.data.message === "success") {
                        getMenus(gID)
                        //populatePermission(gID)
                        showAlert("SUCCESS", "Permission removed", "success")
                        
                    } else {
                        showAlert("ERROR", "netwrok error, please try again.", "error")
                    }
                })
        } catch (e) {
            showAlert("ERROR", "netwrok error, please try again.", "error")
        }
    }
    const AddPermission = (val, gID) => {
        const fdata = {
            GroupID: gID,
            SubSubMenuID: val,
            InsertedBy: formData.InsertedBy
        }
        try {
            axios.post(`${serverLink}user-management/privilege/add`, fdata)
                .then((res) => {
                    if (res.data.message === "success") {
                        getMenus(gID)
                        //populatePermission(gID)
                        showAlert("SUCCESS", "Permission added", "success")
                    } else {
                        showAlert("ERROR", "permission already exist", "error")
                    }
                })
        } catch (e) {
            showAlert("ERROR", "netwrok error, please try again.", "error")
        }

    }

    useEffect(() => {
        getData();
    }, [])

    return isLoading ? (<Loader />) : (
        <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-header pt-5">
                            <PageTitle title={["Manage Users", "Users", "User Permissions"]} />
                            <div className="card-toolbar">
                                <div className="text-center">
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-9 pb-0">
                            <select className="form-control" id="GroupID" onChange={onEdit}>
                                <option value={""}>Select User Groups</option>
                                {
                                    GroupList.length > 0 &&
                                    GroupList.map((x, i) => {
                                        return (
                                            <option key={i} value={x.SN} >{x.PrivilegeDescription}</option>
                                        )
                                    })
                                }

                            </select>
                            <div className="mt-10">
                                <ReportTable columns={columns} data={data} />
                            </div>


                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(Prvileges);

