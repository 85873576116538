

export const str_by_months_char_data = (data_) => {
    let t_counts = [];
    data_.length > 0 &&
        data_.map((x, y) => {
            t_counts.push(x.t_count)
        })
    let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });


    let dates = [];
    data_.length > 0 &&
        data_.map((x, y) => {
            dates.push(x.t_date.split("T")[0])
        })
    let t_date_sorted = dates.sort(function (a, b) { return b - a; });
    const data = {
        series: [{
            name: 'Transactions',
            data: t_counts_sorted
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
            },
            forecastDataPoints: {
                count: 7
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: t_date_sorted,
                tickAmount: 10,
                labels: {
                    formatter: function (value, timestamp, opts) {
                        return opts.dateFormatter(new Date(timestamp), 'dd MMM')
                    }
                }
            },
            title: {
                text: 'Suspicious Transactions Trend for the year 2022',
                align: 'left',
                style: {
                    fontSize: "16px",
                    color: '#666'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    gradientToColors: ['#FDD835'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
            },
            yaxis: {
                min: 0,
                max: 100
            }
        },
    }
    return data;
}


export const watchListChart = (data_)=>{
    let t_counts = [];
    data_.length > 0 &&
        data_.map((x, y) => {
            t_counts.push(x.source_count)
        })
    let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

    let t_source = [];

     data_.length > 0 &&
        data_.map((x, y) => {
            t_source.push(x.source)
        })
    let t_source_sorted = t_source.sort(function (a, b) { return a - b; });

    const randomColor =[];
    data_.length > 0 &&
        data_.map((x,y)=>{
            randomColor.push("#"+Math.floor(Math.random()*16777215).toString(16))
        })

    const chartData ={
        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: t_source_sorted
            }
        },
        series: [
            {
                name: "count",
                data: t_counts_sorted
            }
        ],
        colors: randomColor,
        plotOptions: {
            bar: {
              columnWidth: '100%',
              distributed: true,
            }
          },
    }
    return chartData
    
}

export const str_by_year_chart_data = (data_)=>{
    let t_counts = [];
    data_.length > 0 &&
        data_.map((x, y) => {
            t_counts.push(x.t_count)
        })
    let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

    let t_years = [];
    data_.length > 0 &&
        data_.map((x, y) => {
            t_years.push(x.t_year)
        })
    let t_years_sorted = t_years.sort(function (a, b) { return a - b; });

    const randomColor =[];
    data_.length > 0 &&
        data_.map((x,y)=>{
            randomColor.push("#"+Math.floor(Math.random()*16777215).toString(16))
        })

    const chartData={
        series: t_counts_sorted,
        options: {
          chart: {
            height: 390,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '50%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                }
              }
            }
          },
          colors: randomColor,
          labels: t_years_sorted,
          legend: {
            show: true,
            floating: true,
            fontSize: '12px',
            position: 'right',
            offsetX: 50,
            offsetY: 0,
            labels: {
              useSeriesColors: true,
            },
            markers: {
              size: 0
            },
            formatter: function(seriesName, opts) {
              return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
            itemMargin: {
              vertical: 3
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                  show: false
              }
            }
          }]
        },
    }
    return chartData;
}