import React from "react";
import Modal from "../../common/modal";
import ReportTable from "../../common/table";
const SubSubMenu = (props) => {
    const columns = ["Sub SubMenuID", "Sub SubMenuName", "Sub Menu ID", "Visibility", "SubSubMenuLink", "Inserted By", "Inserted Date", "action"];

    return (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Sub SubMenu Menu</h3>
                </div>
                <button className="btn btn-primary align-self-center"
                    data-bs-toggle="modal"
                    data-bs-target="#SubSubMenu"
                    onClick={props.Reset}
                >Add Sub SubMenu</button>
            </div>
            <div className="card-body p-9">
                <div className="row mb-7">
                    <ReportTable columns={columns} data={props.subsubMenuList} />
                </div>
            </div>
            <Modal type="modal-md" title="Sub SubMenu" subtitle=" " id={"SubSubMenu"}>
                <form id="kt_modal_new_target_form" >

                    <div className="row g-9 mb-8">
                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Main Menu</label>
                            <select className="form-select" onChange={props.onEdit} name="MainMenuID" value={props.createMenu.MainMenuID}>
                                <option value={""}>select main menu</option>
                                {
                                    props.MainMenuList.length > 0 &&
                                    props.MainMenuList.map((x, y) => {
                                        return (
                                            <option value={x.SN} key={y}>{x.MenuName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Sub Menu</label>
                            <select className="form-select" onChange={props.onEdit} name="SubMenuID" value={props.createMenu.SubMenuID}>
                                <option value={""}>select Sub menu</option>
                                {
                                    props.filteredSubMenu.length > 0 &&
                                    props.filteredSubMenu.map((x, y) => {
                                        return (
                                            <option value={x.SN} key={y}>{x.SubMenuName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Sub SubMenu Name</label>
                            <input type="text" className="form-control"
                                placeholder="sub menu name" name="SubSubMenuName"
                                onChange={props.onEdit}
                                value={props.createMenu.SubSubMenuName} />
                        </div>

                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Sub SubMenu Link</label>
                            <input type="text" className="form-control"
                                placeholder="sub menu link" name="SubSubMenuLink"
                                onChange={props.onEdit}
                                value={props.createMenu.SubSubMenuLink} />
                        </div>
                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Visibility</label>
                            <select className="form-select" onChange={props.onEdit} name="Visibility" value={props.createMenu.Visibility}>
                                <option value={""}>select visibility</option>
                                <option value={"1"}>Visible</option>
                                <option value={"0"}>Hidden</option>
                            </select>
                        </div>

                        <div className="d-flex flex-column">
                            <button type="submit" id="kt_modal_new_address_submit" onClick={props.onSubSubMenuSubmit} data-kt-indicator={props.isFormLoading} className="btn btn-sm btn-dark fw-bold">
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}
export default SubSubMenu;