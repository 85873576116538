import React, { Component } from "react";
import "./App.css";
import PageRoutes from "./component/page_routes/page_routes";
import SideBar from "./component/sidebar/sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./component/login/login";
import OuterRoutes from "./component/page_routes/outer_routes";
import { useState } from "react";
// import { toast } from 'react-toastify';

function App(props){
  const [isLoggedIn, setisLoggedIn] = useState(window.sessionStorage.getItem('isLoggedIn'))
    return (
      <div className="App">
          {
              props.loginDetails.length < 1 ?
                  <Router>
                    <OuterRoutes/>
                  </Router>
                  :
                  <Router>
                      <SideBar />
                      <PageRoutes />
                  </Router>
          }
      </div>
    );
}


const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
  };
};

export default connect(mapStateToProps, null)(App);
