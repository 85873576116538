import { Co2Sharp } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import { create } from "@mui/material/styles/createTransitions";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { resolvePath } from "react-router";
import { serverLink } from "../../resources/url";
import PageTitle from "../common/breadcrumb";
import Modal from "../common/modal";
import ReportTable from "../common/table";
import { encryptData, randomCharacters, showAlert } from "../util/generalfunction";
import Loader from "../util/loader";

const AddEditUser = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setisFormLoading] = useState('off');
    const columns = ["sn", "UserID", "Full Name", "Email", "Phone", "Organization", "Department", "role", "Account Status", "User type", "action"];
    const [data, setData] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [departmentList, setdepartmentList] = useState([]);
    const [createUser, setcreateUser] = useState({
        SN: "",
        UserID: "",
        FirstName: "",
        LastName: "",
        OtherName: "",
        EmailAddress: "",
        PhoneNumber: "",
        Organization: "",
        Department: "",
        Role: "",
        AccountStatus: "",
        UserType: "",
        Password: "",
        Passport: "",
        Passport_: "",
        InsertedDate: "",
        UserType:"",
        InsertedBy: props.loginDetails[0].UserName,
    })

    const getUsers = async () => {
        try {
            let roles =[];
            await axios.get(`${serverLink}user-management/get-roles`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    roles = response;
                    setRoleList(response);
                }
            })

            // await axios.get(`${serverLink}user-management/get-departments`).then((result)=>{
            //     let response  = result.data.recordset;
            //     if(response.length > 0){
            //         setdepartmentList(response);
            //     }
            // })

            await axios.get(`${serverLink}user-management/get-all-users`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    let rows = [];
                    response.map((item, index) => {
                        rows.push([
                            index + 1,
                            item.UserID,
                            item.FirstName + " " + item.LastName + " " + item.OtherName,
                            item.EmailAddress,
                            item.PhoneNumber,
                            item.Organization,
                            item.Department,
                            item.Role,
                            //roles.filter(x=>x.RoleID === item.Role)[0].RoleDescription,
                            item.AccountStatus,
                            item.UserType,
                            <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_new_target"
                                onClick={() => {
                                    setcreateUser({
                                        ...createUser,
                                        SN: item.SN,
                                        UserID: item.UserID,
                                        FirstName: item.FirstName,
                                        LastName: item.LastName,
                                        OtherName: item.OtherName,
                                        EmailAddress: item.EmailAddress,
                                        PhoneNumber: item.PhoneNumber,
                                        Organization: item.Organization,
                                        Department: item.Department,
                                        Role: item.Role,
                                        AccountStatus: item.AccountStatus,
                                        UserType: item.UserType,
                                        Password: item.Password,
                                        Passport_: item.Passport,
                                        Passport: ""
                                    });
                                }}
                            >
                                <i className="fa fa-pen" />

                            </button>
                        ])
                    })
                    setData(rows)
                    setIsLoading(false)
                } else {
                    setData([]);
                }
            })
        } catch (e) {
            console.log(e)
            console.log('NETWORK ERROR')
        }

    }
    const [tabledata, settabledata] = useState([])

    const onEdit = (e) => {
        setcreateUser({
            ...createUser,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "Passport") {
            setcreateUser({
                ...createUser,
                [e.target.name]: e.target.files[0]
            })
        }
        if (e.target.name === "Department") {
            console.log(e)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let UserID = randomCharacters(7)
        const reqBody = {
            SN: createUser.SN,
            UserID: UserID,
            FirstName: createUser.FirstName,
            LastName: createUser.LastName,
            OtherName: createUser.OtherName,
            EmailAddress: createUser.EmailAddress,
            PhoneNumber: createUser.PhoneNumber,
            Organization: createUser.Organization,
            Department: createUser.Department,
            Role: createUser.Role,
            AccountStatus: createUser.AccountStatus,
            UserType: createUser.UserType,
            Password: encryptData(randomCharacters(10)),
            InsertedBy: createUser.InsertedBy
        }
        try {
            if (createUser.SN === "") {
                setisFormLoading("on")
                axios.post(`${serverLink}user-management/add-new-user`, reqBody).then((result) => {
                    if (result.data.message === 'success') {
                        const formData = new FormData();
                        formData.append("Passport", createUser.Passport);
                        formData.append("UserID", UserID);
                        axios.put(`${serverLink}user-management/upload-user-passport`, formData).then((result) => {
                            if (result.data.message === "success") {
                                getUsers();
                                setisFormLoading("off")
                                document.getElementById("closeModal").click();
                                showAlert("SUCCESS", "User Added successfully", "success");
                                //document.getElementById("closeModal").click();
                            } else {
                                setisFormLoading("off")
                                showAlert("Error", "An error occures, please try again", "error")
                            }
                        })
                    }
                })
            } else {
                setisFormLoading("on");
                axios.put(`${serverLink}user-management/update-user`, reqBody).then((result) => {
                    if (result.data.message === 'success') {
                        getUsers();
                        setisFormLoading("off")
                        document.getElementById("closeModal").click();
                        showAlert("SUCCESS", "User updated successfully", "success");
                        const formData = new FormData();
                        formData.append("Passport", createUser.Passport);
                        formData.append("UserID", UserID);
                        if (createUser.Passport !== "") {
                            axios.put(`${serverLink}user-management/upload-user-passport`, formData).then((result) => {
                                if (result.data.message === "success") {
                                    showAlert("SUCCESS", "User Added successfully", "success");
                                    //document.getElementById("closeModal").click();
                                } else {
                                    showAlert("Error", "An error occures, please try again", "error")
                                }
                            })
                        }
                    }
                })
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getUsers();
    }, [])



    return isLoading ?
        (<Loader />) : (
            <>
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    <div className="d-flex flex-column flex-column-fluid">
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-fluid">
                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                    <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
                                        <div className="card card-flush h-lg-100">
                                            <div className="card-header pt-5">
                                                <PageTitle title={["Manage Users", "Users", "Add/Edit users"]} />
                                                <div className="card-toolbar">
                                                    <div className="text-center">
                                                        <a href="#" className="btn btn-sm btn-dark fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={() => {
                                                            setcreateUser({
                                                                ...createUser,
                                                                SN: "",
                                                                UserID: "",
                                                                FirstName: "",
                                                                LastName: "",
                                                                OtherName: "",
                                                                EmailAddress: "",
                                                                PhoneNumber: "",
                                                                Organization: "",
                                                                Department: "",
                                                                Role: "",
                                                                AccountStatus: "",
                                                                UserType: "",
                                                                Password: "",
                                                                Passport: "",
                                                                Passport_: "",
                                                            })
                                                        }}>Add User</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body pt-5">
                                                <ReportTable columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Modal type="modal-xl" title="Manage User" subtitle="Create profile for new user"   >
                                    <form id="kt_modal_new_target_form" >
                                        {
                                            createUser.Passport_ !== "" &&
                                            <div className="d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                                <div className="image-input image-input-empty image-input-outline" >
                                                    <div className="image-input-wrapper w-125px h-125px">
                                                        <img style={{ maxWidth: '145px', maxHeight: '125px', borderRadius: '5px' }} src={`${serverLink}public/passports/${createUser.Passport_}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row g-9 mb-8">
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">First Name</label>
                                                    <input type="text" className="form-control" placeholder="firstname" name="FirstName" onChange={onEdit} value={createUser.FirstName} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Middle Name</label>
                                                    <input type="text" onChange={onEdit} className="form-control" placeholder="last name" name="LastName" value={createUser.LastName} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Other Name</label>
                                                    <input type="text" onChange={onEdit} className="form-control" placeholder="other name" name="OtherName" value={createUser.OtherName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Email Address</label>
                                                    <input type="text" onChange={onEdit} className="form-control" placeholder="email addresss" name="EmailAddress" value={createUser.EmailAddress} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Phone Number</label>
                                                    <input type="text" onChange={onEdit} className="form-control" placeholder="phone number" name="PhoneNumber" value={createUser.PhoneNumber} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Organization</label>
                                                    <input type="text" onChange={onEdit} className="form-control" placeholder="organization" name="Organization" value={createUser.Organization} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex fs-6 fw-bolder mb-2">Department</label>
                                                    <select onChange={onEdit} value={createUser.Department} className="form-select form-select-solid" name="Department" >
                                                        <option value="" >Select department...</option>
                                                        <option value={"Compliance"}>Compliance</option>
                                                        <option value={"Regulation"}>Regulation</option>
                                                        <option value={"Monitoring and Evaluation"}>Monitoring and Evaluation</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Role</label>
                                                    <select className="form-select" onChange={onEdit} name="Role" value={createUser.Role} >
                                                        <option value="" >Select role...</option>
                                                        {
                                                            roleList.length > 0 &&
                                                            roleList.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.RoleID}>{item.RoleDescription}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Account Status</label>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form-check form-check-custom form-check-solid me-10">
                                                            <input className="form-check-input h-20px w-20px" onChange={onEdit} type="radio" name="AccountStatus" value="active" checked={createUser.AccountStatus === "active" ? true : false} />
                                                            <span className="form-check-label fw-semibold">Active</span>
                                                        </label>
                                                        <label className="form-check form-check-custom form-check-solid">
                                                            <input className="form-check-input h-20px w-20px" type="radio" onChange={onEdit} name="AccountStatus" value="inactive" checked={createUser.AccountStatus === "inactive" ? true : false} />
                                                            <span className="form-check-label fw-semibold">Inactive</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">User Type</label>
                                                    <select onChange={onEdit} value={createUser.UserType} className="form-select form-select-solid" name="UserType" >
                                                        <option value="" >Select UserType...</option>
                                                        <option value={"Internal"}>Internal</option>
                                                        <option value={"External"}>External</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {
                                                createUser.Passport_ === "" &&
                                                <div className="col-md-4">
                                                    <div className="d-flex flex-column">
                                                        <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Passport</label>
                                                        <input type="file" className="form-control" placeholder="Passport" name="Passport" onChange={onEdit} />
                                                    </div>
                                                </div>
                                            }


                                        </div>
                                        <div className="text-center">
                                            <button type="submit" id="kt_modal_new_address_submit" onClick={onSubmit} data-kt-indicator={isFormLoading} className="btn btn-primary w-150 mb-9 mt-9">
                                                <span className="indicator-label">{
                                                    createUser.SN === "" ? "Submit" : "Update User"
                                                }</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>

                                        </div>
                                        <div />
                                    </form>
                                </Modal>

                            </div>

                        </div>
                    </div>

                </div>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddEditUser);

