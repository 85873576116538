import React, { Component } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import { formatDateAndTime } from "../util/dateformat.jsx";
import ReportTable from "../common/table.jsx";
import Loader from "../util/loader.jsx";
import PageTitle from "../common/breadcrumb.jsx";

class Approvedstr extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      countries: [],
      generatedstrs: [],
      columns: ["S/N", "Transaction ID", "Source Wallet", "Dest. Wallet", "Amount", "Source Country", "Dest. Country", "Trns. Date", "Str_code", "Action", "ApprovedBy", "Date Approved"],
      data: []
    }
  }

  refreshList() {
    fetch(variables.API_URL + 'approved_Str')
      .then(response => response.json())
      .then(data => {
        this.setState({ generatedstrs: data });
        let rows = [];
        let countries_ = []
        if (data.length > 0) {
          data.map((item, index) => {
            countries_.push(item.t_source_country && item.t_dest_country);
            rows.push([
              index + 1,
              <Link to={`/strdetails?str=${item.str_code}&accno=${item.t_account_number}&transno=${item.t_trans_number}`}>{item.t_trans_number}</Link>,
              item.t_source_walletID,
              item.t_dest_walletID,
              item.t_amount_local,
              item.t_source_country,
              item.t_dest_country,
              item.processed_date,
              item.str_code,
              item.reason,
              item.approvedBy,
              formatDateAndTime(item.dateApproved, "date")
            ])
          })
        }
        this.setState({
          data: rows,
          isLoading: false,
          countries: [...new Set(countries_.map((item) => item))]
        })
      });
  }

  componentDidMount() {
    this.refreshList();
  }


  render() {
    const {
      generatedstrs
    } = this.state;

    return this.state.isLoading ? (<Loader />) : (
      <div>
        <div className="container-fluid">
          <div className="card ">
            <div className="card-header pt-7">
            <div className="card-header pt-5">
              <PageTitle title={["AML Engine","Transaction Monitoring", "Approved STR"]} />
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="container-fluid">
            <div className="card ">
              <div className="card-body ">
                <div className="row">
                  <div className="row col-md-12">
                    <div className="col-md-6">
                      <div className="alert alert-dismissible bg-light-warning border border-dashed border-warning p-5 mb-5">
                        <div className="d-flex flex-column pe-0 pe-sm-10">
                          <h5 className="mb-1 fs-1">{this.state.data.length}</h5>
                          <span>Total transactions approved as Suspicious transactions</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                        <div className="alert alert-dismissible bg-light-warning border border-dashed border-warning p-5 mb-5">
                          <div className="d-flex flex-column pe-0 pe-sm-10">
                            <h5 className="mb-1 fs-1">{this.state.countries.length}</h5>
                            <span>Countries Involved</span>
                          </div>
                        </div>
                      </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="form-label-group">
                      <label className="lbltext" htmlFor="requestcommentfrom">
                        Reported Date
                      </label>
                      <input
                        type="date"
                        id="reporteddate"
                        className="form-control"
                        placeholder="Enter name"
                        required="required"
                        autoFocus="autofocus"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-label-group">
                      <label className="lbltext" htmlFor="requestcommentfrom">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="txtEnddate"
                        className="form-control"
                        placeholder="Enter name"
                        required="required"
                        autoFocus="autofocus"
                      />
                    </div>
                  </div> */}
                </div>
                {/* <br />

                <div className="row">
                  <div className="col-md-3">
                    <button
                      className="btn btn-success btn-block"
                      type="submit"
                      disabled={this.state.isLoading ? true : false} >
                      Filter Transactions &nbsp;&nbsp;&nbsp;

                    </button>
                  </div>


                  <div className="col-md-3">
                    <button
                      className="btn btn-primary btn-block"
                      type="submit"
                      disabled={this.state.isLoading ? true : false} >
                      Export to Excel &nbsp;&nbsp;&nbsp;

                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            <br />

            {
              this.state.data.length > 0 &&
              <ReportTable columns={this.state.columns} data={this.state.data} />
            }
          </div>
          <br />
        </form>
      </div>
    );
  }
}
export default Approvedstr;
