import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import { serverLink } from '../../resources/url';
import { showAlert } from '../util/generalfunction';
import { connect } from 'react-redux';
import Loader from '../util/loader';

class Strdetails extends Component {
    constructor(props) {
        const params = new URLSearchParams(window.location.search);
        super(props);
        this.state = {
            isLoading: true,
            isApproveLoading:"off",
            isDismissLoading:"off",
            id: '',
            isLoading: false,
            InsertedBy: this.props.loginDetails[0].UserName,
            StrCode: params.get('str'),
            TransactionNo: params.get('transno'),
            Status: 0,
            Reason: ""
        };
    }



    componentDidMount() {
        this.checkStatus();
        const params = new URLSearchParams(window.location.search);
        const strcode = params.get('str');
        const accountno = params.get('accno');
        const transno = params.get('transno');
        document.getElementById('AccountNumber').value = accountno;
        document.getElementById('TransactionNumber').value = transno;

        var data = {};
        const url = "http://50.59.99.200/enairaAPI/api/tr_STRsby_Code_account_no_trans_no?str_code=" + strcode +
            "&account_no=" + accountno + "&t_trans_number=" + transno;
        axios.get(url)
            .then(res => {
                var info = res.data;
                var object = info[0]; //console.log(object);
                //console.log(object.t_account_number);
                //this.setState({generatedstrs:res.data});
                document.getElementById('TransactionBranch').value = object.t_location;
                document.getElementById('TransactionDate').value = object.t_date;
                document.getElementById('Teller').value = object.t_teller;
                document.getElementById('Authourizer').value = object.t_authorized;
                document.getElementById('TransactionMode').value = object.t_transmode_code;
                document.getElementById('LocalAmount').value = object.t_amount_local;
                document.getElementById('Sourceclienttype').value = object.t_source_client_type;

                document.getElementById('SourceType').value = object.t_source_type;
                document.getElementById('Sourcefundscode').value = object.t_source_funds_code;
                document.getElementById('Sourcecurrencycode').value = object.t_source_currency_code;
                document.getElementById('Sourceforeignamount').value = object.t_source_foreign_amount;
                document.getElementById('Sourceexchangerate').value = object.t_source_exchange_rate;
                document.getElementById('SourceCountry').value = object.t_source_country;
                document.getElementById('Sourceinstitutioncode').value = object.t_source_institution_code;
                document.getElementById('Sourceaccountnumber').value = object.t_source_account_number;
                document.getElementById('Sourceaccountname').value = object.t_source_account_name;
                document.getElementById('Sourcepersonfirstname').value = object.t_source_person_first_name;
                document.getElementById('Sourcepersonlastname').value = object.t_source_person_last_name;

                document.getElementById('Destinationclienttype').value = object.t_dest_client_type;
                document.getElementById('Destinationtype').value = object.t_dest_type;
                document.getElementById('Destinationfundscode').value = object.t_dest_funds_code;
                document.getElementById('Destinationcountry').value = object.t_dest_currency_code;
                document.getElementById('DestinationInstitutionCode').value = object.t_dest_institution_code;
                document.getElementById('Destinationaccountnumber').value = object.t_dest_account_number;
                document.getElementById('Destinationaccountname').value = object.t_dest_account_name;
                document.getElementById('Destinationpersonfirstname').value = object.t_dest_person_first_name;
                document.getElementById('t_dest_person_last_name').value = object.t_dest_person_last_name;
                document.getElementById('STRReason').value = object.issues;
                document.getElementById('ActionTaken').value = "";
                document.getElementById('STRIndicator').value = "";

                this.setState({ isLoading: false })
            })
            .catch(error => {
                console.log(error);
                this.setState({ authError: true, isLoading: false });
            });


    }

    onEdit = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    checkStatus = () => {
        try {
            axios.post(`${serverLink}str/check-status`, this.state).then((result) => {
                let response = [result.data];
                if (response.length > 0) {
                    this.setState({
                        Status: parseInt(response[0].status),
                        Reason: response[0].reason
                    })
                }
            })
        } catch (error) {
            console.log('network error')
        }
    }

    handleDismiss = (e) => {
        this.setState({
            isDismissLoading:"on"
        })
        e.preventDefault();
        // this.setState({isLoading: true});
        const token = localStorage.getItem('token');
        const params = new URLSearchParams(window.location.search);
        const strcode = params.get('str');
        const accountno = params.get('accno');
        const transno = params.get('transno');
        const url = `http://50.59.99.200/enairaAPI/api/approve_Str?str_code=${strcode}&transaction_id=${transno}`;

        try {
            axios.post(`${serverLink}str/dismiss-str`, this.state).then((result) => {
                if (result.data.message === "success") {
                    this.setState({ Status: 2, isDismissLoading:"off" })
                    showAlert("SUCCESS", "STR DISMISSED SUCCESSFULLY", "success")
                }
                else if (result.data.message === "exists") {
                    this.setState({
                        isDismissLoading:"off"
                    })
                    showAlert("EXISTS", "Transaction have already been dismissed from STR", "warning")
                }
                else {
                    this.setState({
                        isDismissLoading:"off"
                    })
                    showAlert("ERROR", "Something went wrong, please try agaian", "error")
                }
            })
        } catch (e) {
            this.setState({
                isDismissLoading:"off"
            })
            console.log('NETWORK ERROR')
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isApproveLoading:"on"
        })
        try {
            axios.post(`${serverLink}str/approve-str`, this.state).then((result) => {
                if (result.data.message === "success") {
                    this.setState({ Status: 1, isApproveLoading:"off" })
                    showAlert("SUCCESS", "STR APPROVED SUCCESSFULLY", "success")
                }
                else if (result.data.message === "exists") {
                    this.setState({
                        isApproveLoading:"off"
                    })
                    showAlert("EXISTS", "Transaction have already been approved for STR", "warning")
                }
                else {
                    this.setState({
                        isApproveLoading:"off"
                    })
                    showAlert("ERROR", "Something went wrong, please try agaian", "error")
                }
            })
        } catch (e) {
            this.setState({
                isApproveLoading:"off"
            })
            console.log('NETWORK ERROR')
        }
    };


    render() {
        const bg_class = this.state.Status === 0 ? "default" : this.state.Status === 1 ? "danger" : "success"

        return this.state.isLoading ? (<Loader />) : (
            <div>

                <div className="container-fluid">

                    <div className="card ">
                        <div className="card-header pt-7">
                            <div className="card-title align-items-start flex-column">
                                <h3>Suspicious Transaction Information Detail</h3>
                                <h2 id="strdesrp" ></h2>
                            </div>
                            <div className='row col-md-12'>
                                <div className={`alert alert-dismissible bg-light-${bg_class} border border-${bg_class} p-5 mb-10`}>
                                    <div className="d-flex flex-column pe-0 pe-sm-10">
                                        <h5 className="mb-1">Transaction Status</h5>
                                        <span>{
                                            this.state.Status === 0 ? "This transaction is pending review, kindly review and make decision" :
                                                this.state.Status === 1 ? "This Transaction have been approved as a Suspicious transaction"
                                                    : "This transaction have been dismissed and is not longer a suspicious transaction"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  </div><br />

                <form >
                    <div className="container-fluid">

                        <div className="card ">
                            <div className="card-body ">

                                <div className="row">
                                    <div className="form-label-group">
                                        <h6 className='lbltext' >Transaction Information</h6>
                                    </div><br /><br />

                                    <div className="col-md-12 ">

                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Reason">Reason </label>
                                            <textarea id="Reason" value={this.state.Reason !== null ? this.state.Reason :""} onChange={this.onEdit} className="form-control" rows="2" cols="50" name="Reason" />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="requestcommentfrom">Request Comment From</label>
                                            <textarea id="requestcommentfrom" className="form-control" rows="2" cols="50" name="Reasonfordismissingstr" />
                                        </div>
                                    </div> */}
                                </div><br />

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <button type="submit" id="kt_modal_new_address_submit" onClick={this.handleSubmit} data-kt-indicator={this.state.isApproveLoading} className="btn btn-sm btn-danger fw-bold">
                                                <span className="indicator-label">Approve STR</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                    <div className="d-flex flex-column">
                                            <button type="submit" id="kt_modal_new_address_submit" onClick={this.handleDismiss} data-kt-indicator={this.state.isDismissLoading} className="btn btn-sm btn-success fw-bold">
                                                <span className="indicator-label">Dismiss STR</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div><br /><br />


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="inputName">Account Number</label>
                                            <input type="text" id="AccountNumber" className="form-control" placeholder="Enter name" required="required" autoFocus="autofocus" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="inputPhone">Transaction Number</label>
                                            <input type="text" id="TransactionNumber" className="form-control" placeholder="Enter Phone" required="required" />
                                        </div>
                                    </div>
                                </div><br />


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="TransactionBranch">Transaction Branch</label>
                                            <input type="text" id="TransactionBranch" className="form-control" placeholder=" Enter Transaction Branch" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="LocalAmount">Local Amount</label>
                                            <input type="text" id="LocalAmount" className="form-control" placeholder="Local Amount" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="TransactionDate">Transaction Date</label>
                                            <input type="text" id="TransactionDate" className="form-control" placeholder="Enter Transaction Date" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Teller">Teller</label>
                                            <input type="text" id="Teller" className="form-control" placeholder="Enter Teller" required="required" />

                                        </div>
                                    </div>
                                </div><br />



                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Authourizer">Authourizer</label>
                                            <input type="text" id="Authourizer" className="form-control" placeholder="Authourizer" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="TransactionMode">Transaction Mode</label>
                                            <input type="text" id="TransactionMode" className="form-control" placeholder="Enter Transaction Mode" required="required" />

                                        </div>
                                    </div>
                                </div><br />

                            </div>
                        </div><br />

                        <div className="card mx-auto">
                            <div className="card-body">

                                <div className="row">
                                    <div className="form-label-group">
                                        <h6 className='lbltext' >Source Information</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourceclienttype">Source client type</label>
                                            <input type="text" id="Sourceclienttype" className="form-control" placeholder="Enter Source client type" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="SourceType">Source Type</label>
                                            <input type="text" id="SourceType" className="form-control" placeholder="Enter Source Type" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourcefundscode">Source funds code</label>
                                            <input type="text" id="Sourcefundscode" className="form-control" placeholder="Source funds code" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourcecurrencycode">Source currency code</label>
                                            <input type="text" id="Sourcecurrencycode" className="form-control" placeholder="Enter Source currency code" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourceforeignamount">Source foreign amount</label>
                                            <input type="text" id="Sourceforeignamount" className="form-control" placeholder="Enter Source foreign amount" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourceexchangerate">Source exchange rate</label>
                                            <input type="text" id="Sourceexchangerate" className="form-control" placeholder="Enter Source exchange rate" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="SourceCountry">Source Country</label>
                                            <input type="text" id="SourceCountry" className="form-control" placeholder="Source Country" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourceinstitutioncode">Source institution code</label>
                                            <input type="text" id="Sourceinstitutioncode" className="form-control" placeholder="Enter Source institution code" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourceaccountnumber">Source account number</label>
                                            <input type="text" id="Sourceaccountnumber" className="form-control" placeholder="Source account number" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourceaccountname">Source account name</label>
                                            <input type="text" id="Sourceaccountname" className="form-control" placeholder="Source account name" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourcepersonfirstname">Source person first name</label>
                                            <input type="text" id="Sourcepersonfirstname" className="form-control" placeholder="Source person first name" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Sourcepersonlastname">Source person last name</label>
                                            <input type="text" id="Sourcepersonlastname" className="form-control" placeholder="Source person last name" required="required" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div><br />

                        <div className="card mx-auto">
                            <div className="card-body">

                                <div className="row">
                                    <div className="form-label-group">
                                        <h6 className='lbltext' >Destination Information</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationclienttype">Destination client type</label>
                                            <input type="text" id="Destinationclienttype" className="form-control" placeholder="Enter Destination client type" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationtype">Destination type</label>
                                            <input type="text" id="Destinationtype" className="form-control" placeholder="Enter Destination type" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationfundscode">Destination funds code</label>
                                            <input type="text" id="Destinationfundscode" className="form-control" placeholder="Destination funds code" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationcountry">Destination country</label>
                                            <input type="text" id="Destinationcountry" className="form-control" placeholder="Enter Destination country" required="required" />
                                        </div>
                                    </div>
                                </div><br />


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="DestinationInstitutionCode">Destination Institution Code</label>
                                            <input type="text" id="DestinationInstitutionCode" className="form-control" placeholder="Destination Institution Code" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationaccountnumber">Destination account number</label>
                                            <input type="text" id="Destinationaccountnumber" className="form-control" placeholder="Destination account number" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationaccountname">Destination account name</label>
                                            <input type="text" id="Destinationaccountname" className="form-control" placeholder="Destination account name" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="Destinationpersonfirstname">Destination person first name</label>
                                            <input type="text" id="Destinationpersonfirstname" className="form-control" placeholder="Destination person first name" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="t_dest_person_last_name">Destination person last name</label>
                                            <input type="text" id="t_dest_person_last_name" className="form-control" placeholder="Destination person last name" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="STRReason">STR Reason</label>
                                            <input type="text" id="STRReason" className="form-control" placeholder="STR Reason" required="required" />
                                        </div>
                                    </div>
                                </div><br />

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="ActionTaken">Action Taken</label>
                                            <input type="text" id="ActionTaken" className="form-control" placeholder="Action Taken" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label-group">
                                            <label className='lbltext' htmlFor="STRIndicator">STR Indicator</label>
                                            <input type="text" id="STRIndicator" className="form-control" placeholder="STR Indicator" required="required" />
                                        </div>
                                    </div>
                                </div>

                                <br />
                            </div>
                        </div>
                    </div><br />

                </form>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(Strdetails);

