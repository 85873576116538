import React from "react";
import { useState } from "react";
import ReportTable from "../../common/table";
import Modal from "../../common/modal";

const PrivlegeGroupsMembers = (props) => {
    const columns = ["SN", "User ID", "GroupID", "InsertedON", "Inserted By", "action"]


    return (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Group members</h3>
                </div>
                <button className="btn btn-primary align-self-center" data-bs-toggle="modal" data-bs-target="#groupMembers" onClick={props.Reset}>Add Group Member</button>
            </div>
            <div className="card-body p-9">
                <div className="row mb-7">
                    <ReportTable columns={columns} data={props.groupMembersList} />
                </div>
            </div>
            <Modal type="modal-md" title="Privilege Group" subtitle=" " id="groupMembers">
                <form id="kt_modal_new_target_form" >
                    <div className="row g-9 mb-8">
                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Staff Name</label>
                            <select className="form-select" onChange={props.onEdit} name="UserID" value={props.createGroup.UserID}>
                                <option value={""}>select user</option>
                                {
                                    props.usersList.length > 0 &&
                                    props.usersList.map((x, y) => {
                                        return (
                                            <option value={x.UserID} key={y}>{x.UserID + "--" + x.FirstName + " " + x.LastName + " " + x.OtherName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Effective Date</label>
                            <select className="form-select" onChange={props.onEdit} name="GroupID" value={props.createGroup.GroupID}>
                                <option value={""}>select user</option>
                                {
                                    props.GroupList.length > 0 &&
                                    props.GroupList.map((x, y) => {
                                        return (
                                            <option value={x.SN} key={y}>{x.PrivilegeDescription}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="d-flex flex-column">
                            <button type="submit" id="kt_modal_new_address_submit" onClick={props.onGroupMemberSubmit} data-kt-indicator={props.isFormLoading} className="btn btn-primary w-150 mb-9 mt-9">
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>

                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default PrivlegeGroupsMembers;