import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AddPermission from "../permission/addpermission";
import Login from "../login/login";
import Logout from "../login/logout";
import NotFound from "../notfound/notfound";
import Dashboard from "../dashboard/dashboard";
import Header from "../Header/header";
import Listgeneratedstrs from "../pages/listgeneratedstrs";
import Generatectrxml from "../pages/generatectrxml";
import Straccountsinvolved from "../pages/straccountsinvolved";
import Strtransactionsinvolved from "../pages/strtransactionsinvolved";
import Strdetails from "../pages/strdetails";
import AddEditUser from "../users/manage-users";
import Generatexmlreport from "../pages/generatexmlreport";
import Approvedstr from "../pages/approvedstr";
import Dismissedstr from "../pages/dismissedstr";
import Strrulesconfigured from "../pages/strrulesconfigured";
import Reportingperson from "../pages/reportingperson";
import SidebarMenus from "../users/menus/menus";
import UserGroups from "../users/groups/user-groups";
import UserRoles from "../users/roles";
import Riskrating from "../pages/riskrating";
import Riskrating_high from "../pages/riskrating_high";
import Riskrating_medium from "../pages/riskrating_medium";
import Riskrating_low from "../pages/riskrating_low";
import Riskratingdetails from "../pages/riskratingdetails";
import Peplistglobal from "../pages/peplistglobal";
import Peplistinhouse from "../pages/peplistinhouse";
import Watchlist from "../pages/watchlist";
import Watchlistsearch from "../pages/watchlistsearch";
import Prvileges from "../users/privileges"
import Sidebar from "../sidebar/sidebar";
import Enairatransanction from "../pages/enairatransanction";
import NewCase from "../case-management/new-case";
import CaseList from "../case-management/case-list";
import KYCList from "../kyc-management/customer-list";
import KYCDetails from "../kyc-management/customer-details";
import Userlogs from "../pages/userlogs";


class PageRoutes extends Component {
    render() {
        return (
            <>
             {/* <Sidebar /> */}
            <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" >
                <Header/>
                <Routes>

                <Route exact path="/kyc/customer-details/:id" element={<KYCDetails/>} />

                    <Route exact path="/kyc/customer-list" element={<KYCList/>} />
                    <Route exact path="/cases/case-list" element={<CaseList/>} />
                    <Route exact path="/cases/manage-case" element={<NewCase/>} />
                    <Route exact path="/users/permissions" element={<Prvileges/>}  />
                    <Route exact path="/users/roles" element={<UserRoles/>} />
                    <Route exact path="/login" element={<Login/>} />
                    <Route exact path="/logout" element={<Logout/>} />
                    <Route exact path="/" element={<Dashboard/>} />
                    <Route exact path="/dashboard" element={<Dashboard/>} />
                    <Route exact path="/menus" element={<SidebarMenus/>} />
                    <Route exact path="/users/groups" element={<UserGroups/>} />
                    <Route exact path="/generated-str-list" element={<Listgeneratedstrs/>} />
                    <Route exact path="/straccountsinvolved" element={<Straccountsinvolved/>} />
                    <Route exact path="/strtransactionsinvolved" element={<Strtransactionsinvolved/>} />
                    <Route exact path="/strdetails" element={<Strdetails/>} />

                    <Route exact path="/strdetails" element={<Strdetails/>} />
                    <Route exact path="/generatexmlreport" element={<Generatexmlreport/>} />
                    <Route exact path="/approvedstr" element={<Approvedstr/>} />
                    <Route exact path="/dismissedstr" element={<Dismissedstr/>} />
                    <Route exact path="/strrulesconfigured" element={<Strrulesconfigured/>} />
                    <Route exact path="/reportingperson" element={<Reportingperson/>} />
                    <Route exact path="/riskrating" element={<Riskrating/>} />
                    <Route exact path="/riskrating-high" element={<Riskrating_high/>} />
                    <Route exact path="/riskrating-medium" element={<Riskrating_medium/>} />
                    <Route exact path="/riskrating-low" element={<Riskrating_low/>} />
                    <Route exact path="/riskratingdetails" element={<Riskratingdetails/>} />
                    <Route exact path="/peplistglobal" element={<Peplistglobal/>} />
                    <Route exact path="/peplistinhouse" element={<Peplistinhouse/>} />
                    <Route exact path="/watchlist" element={<Watchlist/>} />
                    <Route exact path="/watchlistsearch" element={<Watchlistsearch/>} />
                    <Route exact path="/enairatransanction" element={<Enairatransanction/>} />
                    <Route exact path="/userlogs" element={<Userlogs/>} />
                    <Route exact path="/generatectrxml" element={<Generatectrxml/>} />
                    
                    
                    <Route path="*" element={<NotFound/>}/>
                    <Route exact path="/manage-users" element={<AddEditUser/>} />

                </Routes>
                {/* <Footer /> */}
               </div>
               </div>
               </div>
               </>
        )

    }
}

export default PageRoutes;