import React, { Component } from 'react';
import { variables } from '../Variables.jsx';
import { Link } from 'react-router-dom';
import { formatDateAndTime } from '../util/dateformat.jsx';


class Listgeneratedstrs extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            generatedstrs: [],
            modalTitle: "",
            Ind_Name: "",
            id: 0,

        }
    }

    refreshList() {
        try {
            fetch(variables.API_URL + 'tr_STRs_involved')
            .then(response => response.json())
            .then(data => {
                this.setState({ generatedstrs: data });
            });
        } catch (e) {
            alert('network error')
            console.log(e)
        }
    }

    componentDidMount() {
        this.refreshList();
    }



    render() {
        const {
            generatedstrs,
            modalTitle,
            id,
            Ind_Name
        } = this.state;

        return (
            <div>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid">


                            <div className="col-lg-12">

                                <div className="card">

                                    <div className="card-header pt-7">
                                        <div className="card-title align-items-start flex-column">
                                            <h2>Suspicious Transaction Reports (STRs) generated</h2>
                                            <small>Click on the STRCode to view Details of Accounts Involved</small>
                                        </div>
                                    </div>

                                    <div className="card-body table-responsive">
                                        <table id="tbl1" className="table table-bordered table-striped  table-row-dashed align-start h5 gs-0 gy-3 my-0" >

                                            <thead className="bg-dark text-white padding-md " >
                                                <tr>
                                                    <th className="p-0 pb-3 min-w-50px text-middle">SN.</th>
                                                    <th className="p-0 pb-3 min-w-100px text-middle">STR Code</th>
                                                    <th className="p-0 pb-3 max-w-70px text-start">Description</th>
                                                    <th className="p-0 pb-3 min-w-100px text-center">Accounts Involved</th>
                                                    <th className="p-0 pb-3 min-w-100px text-start">Report Date</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {generatedstrs.map((dep, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td ><Link to={`/straccountsinvolved?str=${dep.str_code}&reason=${dep.str_reason}`}>{dep.str_code}</Link></td>
                                                        <td className="p-0 pb-3 min-w-100px text-start" >{dep.str_reason}</td>
                                                        <td>{dep.accounts_involved}</td>
                                                        <td>{formatDateAndTime(dep.report_date, "date")}</td>

                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                    </div>


                                </div></div></div></div>
                </div>
            </div>
        )
    }
}

export default Listgeneratedstrs;