

export const dateFormat=(date_) =>{
    let date = new Date(date_);
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return `${day}-${month}-${year}`;
}

export const formatDateAndTime = (date, option) => {
    if (date !== null) {
      const user_date = new Date(date);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day =
        user_date.getDate() < 10
          ? "0" + user_date.getDate()
          : user_date.getDate();
      const hour =
        user_date.getHours() < 10
          ? "0" + user_date.getHours()
          : user_date.getHours();
      const min =
        user_date.getMinutes() < 10
          ? "0" + user_date.getMinutes()
          : user_date.getMinutes();
      const sec =
        user_date.getSeconds() < 10
          ? "0" + user_date.getSeconds()
          : user_date.getSeconds();
  
      let date_string = "";
      if (option === "date_and_time")
        date_string = `${day}-${monthNames[user_date.getMonth()]
          }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
      else if (option === "date")
        date_string = `${day}-${monthNames[user_date.getMonth()]
          }-${user_date.getFullYear()}`;
      else if (option === "day") date_string = day;
      else if (option === "full_month")
        date_string = monthNames[user_date.getMonth()];
      else if (option === "short_month")
        date_string = monthNamesShort[user_date.getMonth()];
      else if (option === "year_only") date_string = user_date.getFullYear();
  
      return date_string;
    } else {
      return "--";
    }
  };