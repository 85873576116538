import {projectCode} from "../../resources/url";
import swal from "sweetalert";

export const showAlert =(title, msg, type)=> {
    return swal({
        title: title,
        text: msg,
        icon: type,
        button: "OK",
    });
}

export const showSuccessAlert =(title, msg, type) =>{
    return swal({
        title: title,
        text: msg,
        icon: type,
        button: "OK",
    }).then((value) => {
        window.location.href = '/dashboard';
    });
}

export const show300SecsAlert =(title, msg, type)=>{
    return swal(msg, {
        buttons: false,
        timer: 1500,
        icon:type,
        title: title
      });
}

export function  showConfirm(title, msg, type, action)  {
    // type: error, success, warning, confirm
    return swal({
      title: title,
      text: msg,
      icon: type,
      buttons: true,
      dangerMode: true,
    })
  }

  export const currencyConverter = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount);
  };
  

const CryptoJS = require("crypto-js");

export function encryptData(string, val = false){
    let secret_key = val === false ? projectCode + "_ENCRYPT_CAML_V1_0" : projectCode;
    let secret_iv = val === false ? projectCode + "_ENCRYPT_IV_CAML_V1_0_" : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0,16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee,  { iv: ivv});

    let result = decrypted.toString();
    return btoa(result) ;
}

export function decryptData(string, val = false){
    let secret_key = val === false ? projectCode + "_ENCRYPT_CAML_V1_0" : projectCode;
    let secret_iv = val === false ? projectCode + "_ENCRYPT_IV_CAML_V1_0_" : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0,16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
}


export const randomCharacters =(length)=> {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789*$%&@!#=+';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}