import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {  DashBoardDataReducer, LoginDetailsReducer, PermissionDetailsReducer } from "./softamlreducer";

const rootReducer = combineReducers({
    loginDetails: LoginDetailsReducer,
    DashBoardData: DashBoardDataReducer,
    PermissionData: PermissionDetailsReducer,
});

const persistConfig = {
    key: "root",
    storage,
};

export default persistReducer(persistConfig, rootReducer);