import React, { Component } from 'react';
import axios from 'axios';
import {Link, Navigate} from 'react-router-dom';

class RiskRatingdetails extends Component {

    constructor(props) {
        super(props);
      
    }

state = {
        id: '',
        isLoading: false
    };

    componentDidMount() {

        const params = new URLSearchParams(window.location.search);
        const strcode = params.get('str');
        const accountno = params.get('accno');
        const transno = params.get('transno');
      
        
       /*
        document.getElementById('AccountNumber').value = accountno;
        document.getElementById('TransactionNumber').value = transno;
       var data = {};
        const url ="http://50.59.99.200/enairaAPI/api/tr_STRsby_Code_account_no_trans_no?str_code=" + strcode +
         "&account_no="+ accountno +"&t_trans_number="+transno;
        axios.get(url)
          .then(res => {
            var info = res.data;
           var object = info[0]; //console.log(object);
            //console.log(object.t_account_number);
            //this.setState({generatedstrs:res.data});
            document.getElementById('TransactionBranch').value = object.t_location;
            document.getElementById('TransactionDate').value = object.t_date;
            document.getElementById('Teller').value = object.t_teller;
            document.getElementById('Authourizer').value = object.t_authorized;
            document.getElementById('TransactionMode').value = object.t_transmode_code;
            document.getElementById('LocalAmount').value = object.t_amount_local;
            document.getElementById('Sourceclienttype').value = object.t_source_client_type;

            document.getElementById('SourceType').value = object.t_source_type;
            document.getElementById('Sourcefundscode').value = object.t_source_funds_code;
            document.getElementById('Sourcecurrencycode').value = object.t_source_currency_code;
            document.getElementById('Sourceforeignamount').value = object.t_source_foreign_amount;
            document.getElementById('Sourceexchangerate').value = object.t_source_exchange_rate;
            document.getElementById('SourceCountry').value = object.t_source_country;
            document.getElementById('Sourceinstitutioncode').value = object.t_source_institution_code;
            document.getElementById('Sourceaccountnumber').value = object.t_source_account_number   ;
            document.getElementById('Sourceaccountname').value = object.t_source_account_name;
            document.getElementById('Sourcepersonfirstname').value = object.t_source_person_first_name;
            document.getElementById('Sourcepersonlastname').value = object.t_source_person_last_name;

            document.getElementById('Destinationclienttype').value = object.t_dest_client_type;
            document.getElementById('Destinationtype').value = object.t_dest_type;
            document.getElementById('Destinationfundscode').value = object.t_dest_funds_code;
            document.getElementById('Destinationcountry').value = object.t_dest_currency_code;
            document.getElementById('DestinationInstitutionCode').value = object.t_dest_institution_code;
            document.getElementById('Destinationaccountnumber').value = object.t_dest_account_number;
            document.getElementById('Destinationaccountname').value = object.t_dest_account_name;
            document.getElementById('Destinationpersonfirstname').value = object.t_dest_person_first_name;
            document.getElementById('t_dest_person_last_name').value = object.t_dest_person_last_name;
            document.getElementById('STRReason').value = object.issues;
            document.getElementById('ActionTaken').value = "";
            document.getElementById('STRIndicator').value = "";
                     
 
          })
          .catch(error => {
            console.log(error);
            this.setState({authError: true, isLoading: false});
        }); */
 
       
    }

    handleSubmit =()=> {
       // event.preventDefault();
       // this.setState({isLoading: true});
        const token = localStorage.getItem('token');
        const params = new URLSearchParams(window.location.search);
        const strcode = params.get('str');
        const accountno = params.get('accno');
        const transno = params.get('transno');
        const url ="http://50.59.99.200/enairaAPI/api/approve_Str?str_code=" + strcode +
        "&transaction_id=" + transno;
   
       /*  const name = document.getElementById('inputName').value;
        const phone = document.getElementById('inputPhone').value;
        const email = document.getElementById('inputEmail').value;
        const location = document.getElementById('inputLoca').value;
        const empid = document.getElementById('inputEmpId').value;
        const company = document.getElementById('inputComp').value; */
       // axios.put(url, { name: name, phone: phone, email:email, location:location, emp_id:empid, company:company, token:token })  
     axios.put(url, { })
            .then(result => {
                if (result.data.status) {
                    this.setState({ isLoading: false});
                    alert("Approved Successfully");
                }
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    };

   /*  renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/dashboard' />
        }
    }; */

    render() {
        const isLoading = this.state.isLoading;
        /* if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        } */

        return (
        <div>
               
                <div className="container-fluid">
                
                            <div class="card ">
                            <div className="card-header pt-7">
                            <div className="card-title align-items-start flex-column"> 
                                    <h3>Customer Risk Rating Report</h3>
                                    <h2 id="strdesrp" ></h2>   
                            </div>
                            </div> 
                 </div>  </div><br/>
                                
                          <form >
                          <div className="container-fluid">
                              
                              <div className="card ">
                                  <div className="card-body ">

                                              <div className="row">
                                                 
                                                  <div className="col-md-7">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="RiskImpact">Risk Impact/ Reason:</label>
                                                      <textarea rows={'4'} id="RiskImpact" className="form-control"></textarea>
                                                       </div>
                                                  </div>

                                                  <div className="col-md-5">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="inputName">Risk Rate:</label>
                                                       <select  id="RiskRate" className="form-select" name="RiskRate" >
                                                                                <option value="" >SELECT RISK RATE</option>
                                                                                <option value={"1"}>HIGH RISK</option>
                                                                                <option value={"2"}>MEDIUM RISK</option>
                                                                                <option value={"3"}>LOW RISK</option>
                                                                            </select>
                                                     <br/>
                                                                            <button type="submit" id="kt_modal_new_address_submit" style={{width:'100%'}} value="Change Risk Rate" className="btn btn-primary">
                                                                        <span className="indicator-label">Change Risk Rate</span>
                                                                        <span className="indicator-progress">Please wait...
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                                        </span>
                                                                    </button>
                                                     </div>
                                                     
                                                  </div>

                                              </div><br/>
                                         
                                      
                                  </div>
                              </div><br/>
                          
                              <div className="card mx-auto">
                                    <div className="card-body">

                                              <div className="row">
                                              <div className="form-label-group">
                                               </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="CIFID">CIF ID:</label>
                                                          <input type="text" id="CIFID" className="form-control" />
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="AccountOwner">Account Owner:</label>
                                                          <input type="text" id="AccountOwner" className="form-control" />
                                                     </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="AccountName">Account Name:</label>
                                                          <input type="text" id="AccountName" className="form-control" />
                                                     </div>
                                                  </div>
                                              </div><br/>

                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="BVN">BVN:</label>
                                                          <input type="text" id="BVN" className="form-control" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label  className='lbltext' htmlFor="TIN">TIN:</label>
                                                      <input type="text" id="TIN" className="form-control" />
                                                         </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label  className='lbltext' htmlFor="CustomerKYC">Customer KYC:</label>
                                                      <input type="text" id="CustomerKYC" className="form-control" />
                                                         </div>
                                                  </div>

                                              </div><br/>
                                        
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="SectorIndustry">Sector/Industry</label>
                                                          <input type="text" id="SectorIndustry" className="form-control" />
                                                      </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="NatureofBusiness">Nature of Business:</label>
                                                          <input type="text" id="NatureofBusiness" className="form-control" />
                                                      </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="RegNo">Reg No:</label>
                                                          <input type="text" id="RegNo" className="form-control" />
                                                      </div>
                                                  </div>


                                              </div><br/>
                                         
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="DOB">DOB:</label>
                                                          <input type="text" id="DOB" className="form-control" />
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="RiskRate">Risk Rate:</label>
                                                          <input type="text" id="RiskRate" className="form-control" />
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="DOB">DOB:</label>
                                                          <input type="text" id="DOB" className="form-control" />
                                                      </div>
                                                  </div>
                                              </div><br/>
                                        
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="RelationshipStartDate">Relationship Start Date:</label>
                                                          <input type="text" id="RelationshipStartDate" className="form-control" />
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="Email1">Email 1:</label>
                                                          <input type="text" id="Email1" className="form-control" />
                                                     </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="Email2">Email 2:</label>
                                                          <input type="text" id="Email2" className="form-control" />
                                                     </div>
                                                  </div>

                                              </div><br/>
                                         
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="BusinessOccupation">Business Occupation:</label>
                                                          <input type="text" id="BusinessOccupation" className="form-control" />
                                                      </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="BusinessCategory">Business Category</label>
                                                          <input type="text" id="BusinessCategory" className="form-control" />
                                                     </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label  className='lbltext' htmlFor="CustomerProductHolding">Customer Product Holding:</label>
                                                          <input type="text" id="CustomerProductHolding" className="form-control" />
                                                     </div>
                                                  </div>
                                              </div>
                                         
                                  </div>
                              </div><br/>
                          
                              <div className="card mx-auto">
                                  <div className="card-body">
                                         
                                              <div className="row">
                                        
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="AccountOpeningMethod">Account Opening Method:</label>
                                                          <input type="text" id="AccountOpeningMethod" className="form-control" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="TypeofIdentificationused">Destination Type:</label>
                                                          <input type="text" id="TypeofIdentificationused" className="form-control" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="Nationality">Nationality:</label>
                                                          <input type="text" id="Nationality" className="form-control" />
                                                         </div>
                                                  </div>
                                              </div><br/>
                                        
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                         <label className='lbltext' htmlFor="LengthofrelationshipwithBank">Length of relationship with the Bank:</label>
                                                         <input type="text" id="LengthofrelationshipwithBank" className="form-control" />
                                                          </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                         <label className='lbltext' htmlFor="ProductsServices">Products Services:</label>
                                                         <input type="text" id="ProductsServices" className="form-control" />
                                                          </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                         <label className='lbltext' htmlFor="GeographicLocation">Geographic Location:</label>
                                                         <input type="text" id="GeographicLocation" className="form-control" />
                                                          </div>
                                                  </div>

                                              </div><br/>
                                        
                                        
                                              <div className="row">
                                                  <div className="col-md-6">
                                                      <div className="form-label-group">
                                                          <label className='lbltext' htmlFor="CustomerAddress">Customer Address:</label>
                                                          <input type="text" id="CustomerAddress" className="form-control" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                      <div className="form-label-group">
                                                         <label className='lbltext' htmlFor="ResidencyStatus"></label>
                                                         <input type="text" id="ResidencyStatus" className="form-control" />
                                                         </div>
                                                  </div>
                                              </div><br/>
                                         
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                         <label className='lbltext' htmlFor="Beneficial_owner">Beneficial owner:</label>
                                                         <input type="text" id="Beneficial_owner" className="form-control" />
                                                          </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                     <label className='lbltext' htmlFor="Purposeofaccount">Purposeofaccount</label>
                                                     <input type="text" id="Purposeofaccount" className="form-control" />
                                                     </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                     <label className='lbltext' htmlFor="Accounts_with_other_banks">Accounts_with_other_banks:</label>
                                                     <input type="text" id="Accounts_with_other_banks" className="form-control" />
                                                     </div>
                                                  </div>

                                              </div><br/>
                                          
                                              <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                       <label className='lbltext' htmlFor="t_dest_person_last_name">Source of fund:</label>
                                                       <input type="text" id="t_dest_person_last_name" className="form-control"  />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="CountryDoingBusinesswith">Country Doing Business with</label>
                                                      <input type="text" id="CountryDoingBusinesswith" className="form-control" />
                                                     </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="NatureofBusiness">Nature of Business</label>
                                                      <input type="text" id="NatureofBusiness" className="form-control" />
                                                     </div>
                                                  </div>
                                              </div><br/>
                                         
                                              <div className="row">

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                     <label className='lbltext' htmlFor="ActionTaken">Type of Customer</label>
                                                     <input type="text" id="ActionTaken" className="form-control" />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                     <label className='lbltext' htmlFor="LargestSingleTransactionForindividuals">Largest Single Transaction For individuals:</label>
                                                     <input type="text" id="LargestSingleTransactionForindividuals" className="form-control" />
                                                        </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                     <label className='lbltext' htmlFor="CumulativeMonthlyValueOfTransactionsIndividuals">Cumulative Monthly Value Of Transactions Individuals</label>
                                                     <input type="text" id="CumulativeMonthlyValueOfTransactionsIndividuals" className="form-control" />
                                                    </div>
                                                  </div>

                                              </div>

                                              <div className="row">

                                                <div className="col-md-3">
                                                    <div className="form-label-group">
                                                <label className='lbltext' htmlFor="LargestSingleTransactionsCorporateAccounts">Largest Single Transactions For Corporate Accounts:</label>
                                                <input type="text" id="LargestSingleTransactionsCorporateAccounts" className="form-control" />
                                                </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-label-group">
                                                <label className='lbltext' htmlFor="CumulativeMonthlyValueOftransactionsCorporateAccounts">Cumulative Monthly Value Of transactions (Corporate Accounts):</label>
                                                <input type="text" id="CumulativeMonthlyValueOftransactionsCorporateAccounts" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-label-group">
                                                <label className='lbltext' htmlFor="AutomaticHighRisk">Automatic High Risk:</label>
                                                <input type="text" id="AutomaticHighRisk" className="form-control" />
                                                </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-label-group">
                                                <label className='lbltext' htmlFor="TotalRiskRate">Total Risk Rate:</label>
                                                <input type="text" id="TotalRiskRate" className="form-control" />
                                                </div>
                                                </div>

                                                </div>
                                          <br/>

                                          <div className="row">
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="inputName">Generated By:</label>
                                                       <input type="text" id="generatedby" className="form-control" placeholder="" />
                                                           </div>
                                                  </div>
                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="GeneratedDate">Generated Date:</label>
                                                     <input type="text" id="GeneratedDate" className="form-control" placeholder="" />
                                                       </div>
                                                  </div>

                                                  <div className="col-md-4">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="Entity">Entity:</label>
                                                     <input type="text" id="Entity" className="form-control" placeholder="" />
                                                       </div>
                                                  </div>
                                              </div><br/>
                                         


                                  </div>
                              </div>
                              </div><br/>
                          
                              </form>
                            
        </div>
        );
    }
}
export default RiskRatingdetails;

