export const setLoginDetails = (p) => {
    return {
        type: "SET_LOGIN_DETAILS",
        payload: p,
    };
};


export const setDashBoardData = (p) => {
    return {
        type: "SET_DASHBOARD_DATA",
        payload: p,
    };
};

export const setPermissionDetails = (p) => {
    return {
        type: "SET_PERMISSION_DETAILS",
        payload: p,
    };
};