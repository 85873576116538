import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setDashBoardData, setLoginDetails } from "../../actions/softamlactions";

const Logout =(props)=> {

    useEffect(()=>{
        onLogoutCall();
    }, [])

    const onLogoutCall = () =>{
        window.sessionStorage.setItem("isLoggedIn", "0");
        props.setOnLoginDetails([]);
        props.setonDashBoardData([]);
        //window.sessionStorage.clear();
        window.location.href = '/';
    }

    return(
        <>
        </>
    )

}



const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setonDashBoardData: (p)=>{
            dispatch(setDashBoardData(p));
        }
    };
};

export default connect(null, mapDisptachToProps)(Logout);
