import React, { useState } from "react";
import Modal from "../../common/modal";
import ReportTable from "../../common/table";
const MainMenu = (props) => {
    const columns = ["MenuID", "MenuName", "Inserted By", "Inserted Date", "action"];
   
    return (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Main Menu</h3>
                </div>
                <button className="btn btn-primary align-self-center" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Add Main Menu</button>
            </div>
            <div className="card-body p-9">
                <div className="row mb-7">
                    <ReportTable columns={columns} data={props.menuList} />
                </div>
            </div>
            <Modal type="modal-md" title="main menu" subtitle=" " id="kt_modal_new_target">
                <form id="kt_modal_new_target_form" >

                    <div className="row g-9 mb-8">
                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Main Menu Name</label>
                            <input type="text" className="form-control" 
                            placeholder="menu name" name="MainMenuName" 
                            onChange={props.onEdit} 
                            value={props.createMenu.MainMenuName} />
                        </div>

                        <div className="d-flex flex-column">
                        <button type="submit" id="kt_modal_new_address_submit" onClick={props.onMenuSubmit} data-kt-indicator={props.isFormLoading} className="btn btn-sm btn-dark fw-bold">
                                                <span className="indicator-label">Submit</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default MainMenu;