import React from "react";

const Modal = (props) => {
    const type = !props.type ? "modal-md" : props.type;
    const subtitle = !props.subtitle ? "": props.subtitle;
    const title = !props.title ? "": props.title;


    return (

        <div className="modal fade" id={`${typeof props.id !== 'undefined' ? props.id : 'kt_modal_new_target'}`}
            tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${type}`}>
                <div className="modal-content rounded">
                    <div className="modal-header pb-0 border-0 justify-content-end">
                        <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" id={`${typeof !props.close ? 'closeModal': props.close }`}>
                            <span className="svg-icon svg-icon-1" id="closeModal">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x={6} y="17.3137" width={16} height={2} rx={1} transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                    <rect x="7.41422" y={6} width={16} height={2} rx={1} transform="rotate(45 7.41422 6)" fill="currentColor" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-10">
                        <div className="mb-3 text-center">
                            <h1 className="mb-1">{title}</h1>
                            <div className="text-muted fw-semibold fs-5">{subtitle}</div>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;