import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import ReportTable from "../common/table.jsx";
import { formatDateAndTime } from "../util/dateformat.jsx";
import { showAlert, showConfirm } from "../util/generalfunction.jsx";
import Loader from "../util/loader";
import { serverLink } from "../../resources/url";

const Watchlistsearch = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [generatedstrs, setgeneratedstrs] = useState([])
  const columns = ["SN", "RecordID" ,"Ind_name" ,"ind_AKA","City", "countrycode","datebirth", "source","Percentage Match"];
  const [data, setData] = useState([]);
  const [strByWalletID, setstrByWalletID] = useState([]);

  const [formData, setFormData] = useState({
    Ind_name:""
  })
  

  const refreshList =()=>{

    const params = new URLSearchParams(window.location.search);
    const searchname = params.get('name');
   
    fetch(`${variables.API_URL}searchwatchlist?name=${searchname}`)
      .then(response => response.json())
      .then(data => {
       // setstrByWalletID(data)
        let rows = [];
        data.length > 0 &&
          data.map((x, y) => {
            rows.push([
              y + 1,
            x.RecordId,
            x.Ind_Name,
            x.Ind_aka,
            x.City,
            x.CountryCode,
            x.DateBirth,
            x.Source,
            x.percentage_match

            ])
          })
        setData(rows)
        setIsLoading(false)
      });

  }

  const SaerchbyName =(e)=>{
    e.preventDefault();
    fetch(`${variables.API_URL}searchwatchlist?name=${formData.Ind_name}`)
      .then(response => response.json())
      .then(data => {
        setstrByWalletID(data)
        let rows = [];
        data.length > 0 &&
          data.map((x, y) => {
            rows.push([
              y + 1,
            x.RecordID,
            x.Ind_Name,
            x.Ind_aka,
            x.City,
            x.countrycode,
            x.datebirth,
            x.source,
            x.source,
            x.percentage_match

            ])
          })
        setData(rows)
        setIsLoading(false)
      });

  }


  useEffect(() => {
    refreshList();
  }, [])

  const onEdit=(e)=>{
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
  }

  return isLoading ? (<Loader />) : (
    <div>
      <div className="container-fluid">
        <div className="card ">
          <div className="card-header pt-7">
            <div className="card-title align-items-start flex-column">
              <h3> Sanction List</h3>
              <h2 id="strdesrp"></h2>
            </div>
          </div>
        </div>
      </div>

      <form >
        <div className="container-fluid">
      
      {/*   <div className="card ">
            <div className="card-body ">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-label-group">
                    <label className="lbltext" htmlFor="Reasonfordismissingstr">
                      Individual/Organisation Name</label>

                    <input
                      type="text"
                      id="Ind_name"
                      className="form-control"
                      placeholder="Enter Name"
                      autoFocus="autofocus" onChange={onEdit}
                    />
                  
                  </div>
                </div>
                <div className="col-md-3">
                <button
                      className="btn btn-primary mt-5" style={{width:'100%'}}
                      type="submit" onClick={SaerchbyName} >
                     Search by Name
                    </button>
                </div>
                
              </div>
              <br />
            </div>
          </div> */}
         

          <div className="card-body pt-5">
            <ReportTable columns={columns} data={data} />
          </div>
          
         
        </div>
        <br />
      </form>
    </div>
  );

}
export default Watchlistsearch;