import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { projectLogo, projectName, serverLink } from "../../resources/url";
import LOGO from '../../images/e-naira-logo.png'
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import SubSubMenu from "../users/menus/sub-sub-menu";
import { set } from "immutable";

const SideBar = (props) => {
    const [mainMenuList, setMainMenuList] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);
    const [SubSUbbMenuList, setSubSubMenuList] = useState([]);
    const [permissions, setpermissions] = useState(props.PermissionData.length > 0 ? props.PermissionData : []);

    const [mainM, setMainM] = useState([])
    const [subM, setsubM] = useState([])
    const [subsubM, setsubsubM] = useState([])
    const icons = ["fas fa-layer-group", "fa fa-user-alt", "fab fa-deezer", "fa fa-calendar-check", "fas fa-money-bill", "fa fas fa-users-cog", "fab fa-mendeley", "fas fa-wave-square", "fas fa-th"]

    useEffect(() => {
        if (props.PermissionData.length > 0) {
            let menulist = [...new Set(props.PermissionData.map((item) => item.MenuName))]
            setMainM(menulist)

            let sub_m = []
            let dis_sub_m = []
            if (menulist.length > 0) {
                props.PermissionData.map((x) => {
                    sub_m.push({ submenu: x.SubMenuName, menu: x.MenuName, link: x.SubMenuLink });
                });
                dis_sub_m = [
                    ...new Set(
                        sub_m.map((item) => item.submenu + "," + item.menu + "," + item.link)
                    ),]
                setsubM(dis_sub_m)
            }

            let sub_sub_menu_ = [];
            let menu_item_array = [];
            if (dis_sub_m.length > 0) {
                dis_sub_m.map((menu) => {
                    props.PermissionData
                        .filter((i) => i.SubMenuName === menu.split(",")[0])
                        .map((x) => {
                            menu_item_array.push(x);
                        });
                });
                sub_sub_menu_ = [
                    ...new Set(
                        menu_item_array.map((item) => item.MenuName + "," + item.SubMenuName + "," + item.SubSubMenuName + "," + item.SubSubMenuLink)
                    )
                ];
                setsubsubM(sub_sub_menu_);
            }
        }
    }, [])

    const getMenu = () => {
        try {
            axios.get(`${serverLink}user-management/main-menu-list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    setMainMenuList(response);
                }
            })
            axios.get(`${serverLink}user-management/sub-menu-list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    setSubMenuList(response)
                }
            })
            axios.get(`${serverLink}user-management/sub-sub-menu-list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    setSubSubMenuList(response)
                }
            })
        } catch (e) {
            console.log('NETWROK ERROR')
        }
    }
    useEffect(() => {
        // getMenu();
    }, [])
    return (
        <div id="kt_aside" className="aside aside-dark aside-hoverable" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
            <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                {/* <a href="/">
                    <img alt="Logo" src={LOGO} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '60px', width: '70px' }} />
                </a> */}

                <div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
                    <span className="svg-icon svg-icon-1 rotate-180">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="black" />
                            <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="black" />
                        </svg>
                    </span>
                </div>

            </div>

            <div className="aside-menu flex-column-fluid">
                <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="0">
                    <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true" style={{ fontSize: '16px' }}>
                        <div className="menu-item">
                            <div className="menu-content pb-2">
                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">Dashboard</span>
                            </div>
                        </div>
                        <div className="menu-item">
                            <NavLink to='/dashboard'>
                                <span className="menu-link active">
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
                                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
                                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
                                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
                                            </svg>
                                        </span>
                                    </span>
                                    <span className="menu-title">Dashboard</span>
                                </span>
                            </NavLink>

                        </div>
                        <div>
                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fas fa-th text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">AML Engine</span>
                                    <span className="menu-arrow" />
                                </span>

                                <div className="menu-sub menu-sub-accordion menu-active-bg">

                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            <span className="menu-link">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">Monitoring and STR</span>
                                                <span className="menu-arrow" />
                                            </span>
                                            <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                <div className="menu-item">
                                                    <Link to="/generated-str-list" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">Transactions Monitoring</span>
                                                    </Link>
                                                    <Link to="/generatexmlreport" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">Generate XML STR</span>
                                                    </Link>
                                                    <Link to="/approvedstr" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">Approved STR</span>
                                                    </Link>
                                                    <Link to="/dismissedstr" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">Dismissed STR</span>
                                                    </Link>

                                                    <Link to="/strrulesconfigured" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">STR Rules Configured</span>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        
                                        <Link to="/generatectrxml" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">Generate CTR/FTR XML </span>
                                                    </Link>
                                        </div>
                                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                            <Link to="/reportingperson" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <span className="menu-title">Reporting Person</span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <span className="fas fa-wave-square text-primary" />
                                    </span>
                                    <span className="menu-title">Customer Risk Rating</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">
                                    <div className="menu-item">
                                        <Link to="/riskrating" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot" />
                                            </span>
                                            <span className="menu-title">Risk Rating Profiling</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/riskrating-high" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot" />
                                            </span>
                                            <span className="menu-title">High Risk Customers</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/riskrating-medium" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot" />
                                            </span>
                                            <span className="menu-title">Medium Risk Customers</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/riskrating-low" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot" />
                                            </span>
                                            <span className="menu-title">Low Risk Customers</span>
                                        </Link>
                                    </div>

                                </div>
                            </div>


                            
                        <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                            <span className="menu-link">
                                <span className="menu-icon">
                                    {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                    <span className="fab fa-mendeley text-primary" />
                                    {/* <!--end::Svg Icon--> */}
                                </span>
                                <span className="menu-title">Sanction/ Screening</span>
                                <span className="menu-arrow" />
                            </span>
                            <div className="menu-sub menu-sub-accordion menu-active-bg">

                              
                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot" />
                                        </span>
                                        <span className="menu-title">PEP List</span>
                                        <span className="menu-arrow" />
                                    </span>
                                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                                        <div className="menu-item">
                                            <Link to="/peplistglobal" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <span className="menu-title">Global PEP List</span>
                                            </Link>
                                            <Link to="/peplistinhouse" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <span className="menu-title">InHouse PEP List</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>

                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                    <Link to="/watchlist" className="menu-link">
                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                        <span className="menu-title">Watch List</span>
                                    </Link>
                                            <Link to="/watchlist" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <span className="menu-title">CBN Sanction List</span>
                                            </Link>
                                            <Link to="/watchlist" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <small className="menu-title">Validate user from Sanction List</small>
                                            </Link>
                                           
                                 </div>


                            </div>
                        </div>


                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <span className="fa fa-calendar-check text-primary" />
                                    </span>
                                    <span className="menu-title">Case Management</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">
                                    <div className="menu-item">
                                        <Link to="/cases/case-list" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">Case List</span>
                                        </Link>

                                        <Link to="/cases/manage-case" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">Open New Case</span>
                                        </Link>

                                    </div>
                                </div>
                            </div>

                       
                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fas fa-money-bill text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">KYC Management</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">

                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div className="menu-item">
                                            <Link to="/kyc/customer-list" className="menu-link">
                                                    <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                    <span className="menu-title">Customer's KYC </span>
                                            </Link>

                                             {/*   <Link to="/strrulesconfigured" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <span className="menu-title">KYC Rules</span>
                                            </Link>
 */}
                                            </div>
                                        </div>
                                   
                                </div>
                            </div>

                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fas fa-money-bill text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">KYT Management</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">

                                     
                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div className="menu-item">
                                            <Link to="/generated-str-list" className="menu-link">
                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                        <span className="menu-title">Customer's KYT</span>
                                                    </Link>

                                              {/*  <Link to="/strrulesconfigured" className="menu-link">
                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                <span className="menu-title">KYT Rules</span>
                                            </Link> */}

                                            </div>
                                        </div>
                                   
                                </div>
                            </div>


                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fa fa-calendar-check text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">e-Naira Payment</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">
                                    <div className="menu-item">
                                        <Link to="/" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot" />
                                            </span>
                                            <span className="menu-title">New Transaction</span>
                                        </Link>
                                    </div>

                                </div>
                            </div>


                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fab fa-deezer text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">Reports</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">
                                    <div className="menu-item">
                                    <Link to="/generated-str-list" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">STR</span>
                                        </Link>
                                        <Link to="/generatectrxml" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">CTR/FTR</span>
                                        </Link>
                                    
                                      
                                        <Link to="/" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">Sanction Report</span>
                                        </Link>

                                        <Link to="/" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">KYC Report</span>
                                        </Link>

                                       {/*  <Link to="/" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">SRFT</span>
                                        </Link>

                                        <Link to="/" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">TMR</span>
                                        </Link>
                                        <Link to="/" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">WAR</span>
                                        </Link>
                                        */}
                                        
                                    </div>
                                </div>
                            </div>


                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fa fa-user-alt text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">User Management</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">

                                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        <Link to="/manage-users" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">Manage Users</span>
                                        </Link>
                                    </div>
                                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        <Link to="/users/roles" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">User Roles</span>
                                        </Link>
                                    </div>
                                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        <Link to="/manage-users" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">Departments</span>
                                        </Link>
                                    </div>

                                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        <span className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot" />
                                            </span>
                                            <span className="menu-title">User Privileges</span>
                                            <span className="menu-arrow" />
                                        </span>
                                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                                            <div className="menu-item">
                                                <Link to="/menus" className="menu-link">
                                                    <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                    <span className="menu-title">Menus</span>
                                                </Link>
                                                <Link to="/users/groups" className="menu-link">
                                                    <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                    <span className="menu-title">Groups</span>
                                                </Link>
                                                <Link to="/users/permissions" className="menu-link">
                                                    <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                    <span className="menu-title">Permissions</span>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>

                                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        <Link to="/manage-users" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">Manage Password</span>
                                        </Link>
                                    </div>

                                </div>
                            </div>

                         
                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                                        <span className="fas fa-layer-group text-primary" />
                                        {/* <!--end::Svg Icon--> */}
                                    </span>
                                    <span className="menu-title">Audit Trail</span>
                                    <span className="menu-arrow" />
                                </span>
                                <div className="menu-sub menu-sub-accordion menu-active-bg">

                                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                        <Link to="/Userlogs" className="menu-link">
                                            <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                            <span className="menu-title">User Logs</span>
                                        </Link>
                                    </div>
                                   
                                </div>
                            </div>


                        </div>
                        <div className="menu-item">
                            <div className="menu-content pb-2">
                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">Dynamic Menus *test data*</span>
                            </div>
                        </div>
                        {
                            mainM.length > 0 &&
                            mainM.sort().map((mm, y) => {
                                var icon_ = icons[Math.floor(Math.random() * icons.length)];
                                var _ic_ = ["fas fa-layer-group", "fa fa-user-alt", "fab fa-deezer", "fa fa-calendar-check", "fas fa-money-bill", "fa fas fa-users-cog", "fab fa-mendeley", "fas fa-wave-square", "fas fa-th"]
                                let ic_ = mm==="AML Engine" ? "fas fa-th" :mm==="User Management" ?  "fa fas fa-users-cog" : mm==="Sanction" ?  "fab fa-mendeley text-primary" :"fa fa-calendar-check"
                                return (
                                    <div key={y}>
                                        {
                                            mm !== null &&
                                            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                                <span className="menu-link">
                                                    <span className="menu-icon">
                                                        <span className={`${ic_} text-primary`} />
                                                    </span>
                                                    <span className="menu-title">{mm}</span>
                                                    <span className="menu-arrow" />
                                                </span>
                                                <div className="menu-sub menu-sub-accordion menu-active-bg">
                                                    <>
                                                        {
                                                            subM.length > 0 &&
                                                            subM.sort().filter(x => x.split(",")[1] === mm).map((f, u) => {
                                                                return (
                                                                    < div key={u}>
                                                                        {
                                                                            f.split(",")[2] !== "null" ?
                                                                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                                                                    <Link to={`${f.split(",")[2]}`} className="menu-link">
                                                                                        <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                                                        <span className="menu-title">{f.split(",")[0]}</span>
                                                                                    </Link>
                                                                                </div>
                                                                                :
                                                                                <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                                                                                    <span className="menu-link">
                                                                                        <span className="menu-bullet">
                                                                                            <span className="bullet bullet-dot" />
                                                                                        </span>
                                                                                        <span className="menu-title">{f.split(",")[0]}</span>
                                                                                        <span className="menu-arrow" />
                                                                                    </span>
                                                                                    {
                                                                                        subsubM.length > 0 &&
                                                                                        subsubM.sort().filter(ssm => ssm.split(",")[1] === f.split(",")[0]).map((s, k) => {
                                                                                            return (
                                                                                                <div key={k} className="menu-sub menu-sub-accordion menu-active-bg">
                                                                                                    <div className="menu-item">
                                                                                                        {
                                                                                                            s.split(",")[2] !== "null" &&
                                                                                                            <Link to={`${s.split(",")[3]}`} className="menu-link">
                                                                                                                <span className="menu-bullet"> <span className="bullet bullet-dot" /></span>
                                                                                                                <span className="menu-title">{s.split(",")[2]}</span>
                                                                                                            </Link>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>


                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
        PermissionData: state.PermissionData
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);