export const LoginDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_LOGIN_DETAILS":
            return action.payload;

        default:
            return state;
    }
};


export const DashBoardDataReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_DASHBOARD_DATA":
            return action.payload;

        default:
            return state;
    }
};

export const PermissionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_PERMISSION_DETAILS":
            return action.payload;

        default:
            return state;
    }
};


