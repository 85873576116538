import React, { Component } from 'react';
import { variables } from '../Variables.jsx';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReportTable from '../common/table.jsx';
import { currencyConverter } from '../util/generalfunction.jsx';


class Straccountsinvolved extends Component {


    constructor(props) {
        super(props);

        this.state = {
            generatedstrs: [],
            modalTitle: "",
            Ind_Name: "",
            reason: "",
            id: 0,
            columns: ["SN", "Account No", "Account Name", "Brnach", "Balance", "Account Type"],
            data: []
        }
    }



    refreshList() {
        fetch(variables.API_URL + 'tr_STRsbyCode')
            .then(response => response.json())
            .then(data => {
                this.setState({ generatedstrs: data });
            });

    }

    componentDidMount() {
        //this.refreshList();

        const params = new URLSearchParams(window.location.search);
        const strcode = params.get('str');
        const reason = params.get('reason');
        this.setState({ strreason: reason });

        // alert(yourParamName);
        document.getElementById('strdesrp').innerHTML = strcode + ' - ' + reason;

        var data = {};
        const url = "http://50.59.99.200/enairaAPI/api/tr_STRsbyCode?str_code=" + strcode;
        axios.get(url, data, {
            headers: { email: this.Ind_Name }
        })
            .then(res => {
                // var info = res.data;
                // var object = info[0]; //console.log(object);
                // console.log(object.account_name);
                this.setState({ generatedstrs: res.data });
                let rows = []
                res.data.length > 0 &&
                    res.data.map((x, i) => {
                        rows.push([
                            (<Link to={`/strtransactionsinvolved?str=${x.str_code}&reason=${this.state.strreason}&accno=${x.t_account_number}`}>
                            {x.t_account_number}</Link>),
                            x.account_name,
                            x.account_branch,
                            currencyConverter(x.account_balance),
                            x.account_balance,
                            x.account_type,
                        ])
                    })
                this.setState({ data: rows })
            })
            .catch(error => {
                console.log(error);
                this.setState({ authError: true, isLoading: false });
            });

    }



    render() {
        console.log(this.state)
        const {
            generatedstrs,
            modalTitle,
            reason,
            id,
            Ind_Name
        } = this.state;

        return (
            <div>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <div className="col-lg-12">
                           
                                <div className="card mb-2">

                                    <div className="card-header p-3">
                                        <div className="card-title align-items-start flex-column">
                                            <h4 id="strdesrp" ></h4>
                                            <h4>List of Accounts Involved</h4>
                                            <small>Click on the Account No to view Details of Accounts Involved</small>
                                        </div>
                                    </div>
                                    {/* <div className="card-body">
                                        
                                        <table id="tbl1" className="table table-bordered table-striped  table-row-dashed align-start h5 gs-0 gy-4 my-0" >

                                            <thead className="bg-dark text-white padding-md " >

                                                <tr>
                                                    <th>Account No</th>
                                                    <th>Account Name</th>
                                                    <th>Branch</th>
                                                    <th>Balance</th>
                                                    <th>Account Type</th>
                                                    <th>Category</th>
                                                    <th>Action</th>
                                                    <th>Dismiss All</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {generatedstrs.map((dep, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td><Link to={`/strtransactionsinvolved?str=${dep.str_code}&reason=${this.state.strreason}&accno=${dep.t_account_number}`}>
                                                                {dep.t_account_number}</Link></td>

                                                            <td>{dep.account_name}</td>
                                                            <td>{dep.account_branch}</td>
                                                            <td>{dep.account_balance}</td>
                                                            <td>{dep.account_type}</td>
                                                            <td>Transaction</td>
                                                            <td></td>
                                                            <td> <input type="checkbox" /></td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div> */}
                                </div>
                                <ReportTable columns={this.state.columns} data={this.state.data} />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}
export default Straccountsinvolved;
