import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import { serverLink } from '../../resources/url';
import { showAlert } from '../util/generalfunction';
import { connect } from 'react-redux';
import Loader from '../util/loader';
import { variables } from '../Variables.jsx';

class Enairatransanction extends Component {
    constructor(props) {
        const params = new URLSearchParams(window.location.search);
        super(props);
        this.state = {
            isLoading: true,
            isApproveLoading:"off",
            isDismissLoading:"off",
            id: '',
            isLoading: false,
            InsertedBy: this.props.loginDetails[0].UserName,
            StrCode: params.get('str'),
            TransactionNo: params.get('transno'),
            Status: 0,
            Reason: ""
        };
    }


    componentDidMount() {
       
    }

    onEdit = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
 

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isApproveLoading:"on"
        })
        try {

            const tdata = {  t_source_walletID:'137244423',
            t_date:'2022-08-11', t_amount_local:'344000', 
            t_dest_walletID:'116881642' };

            axios.post(`${variables.API_URL}new_transaction`, tdata).then((result) => {
                if (result.data === "successful transactions") {
                    this.setState({ Status: 1, isApproveLoading:"off" })
                    showAlert("SUCCESS", "STR APPROVED SUCCESSFULLY", "success")
                }
                else {
                    this.setState({
                        isApproveLoading:"off"
                    })
                    showAlert("ERROR", "Something went wrong, please try agaian", "error")
                }
            })
        } catch (e) {
            this.setState({
                isApproveLoading:"off"
            })
            console.log('NETWORK ERROR')
        }
    };


    render() {
        const bg_class = this.state.Status === 0 ? "default" : this.state.Status === 1 ? "danger" : "success"

        return this.state.isLoading ? (<Loader />) : (
          
            <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Process Transaction Information</h1>
              
            </div>
            {/* Page Heading */}
            <div className="card shadow mb-4">
                <div className="card-body">
                <div className="row mb-2">
                    <div className="col-10">
                    <form >
                        <div style={{display: 'none'}}>                    
                        <div className="row">
                            <div className="form-group col-md-4">
                            <label htmlFor="dateGenerated">Start Date</label>
                            <input type="date" id="startDate" className="form-control" name="startDate" required />
                            {/* <div class="invalid-feedback" *ngIf="dateGenerated.touched && dateGenerated.invalid">
                                <span>Pick Start date</span>
                            </div> */}
                            </div>
                            <div className="form-group col-md-4">
                            <label htmlFor="endDate">End Date</label>
                            <input type="date" id="endDate" className="form-control" name="endDate" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                            <div className="d-sm-flex align-items-right">
                                <button href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm ">
                                Process Transaction from Live</button>
                            </div>
                            </div>
                            <div className="col-md-4">
                            <div className="d-sm-flex align-items-right">
                                <button href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm ">
                                Spool EFCC Report</button>
                            </div>
                        </div>
                        </div>
                        </div>
                    </form>
                    <form >
                        <div className="row col-md-4">
                        <div className="form-group col-md-12" >
                            <label htmlFor="t_source_walletID">Source Wallet ID</label>
                            <input type="text" id="t_source_walletID" className="form-control" name="t_source_walletID" required />
                            {/* <div class="invalid-feedback" *ngIf="dateGenerated.touched && dateGenerated.invalid">
                                <span>Pick Start date</span>
                            </div> */}
                        </div>
                        <div className="form-group col-md-12" >
                            <label htmlFor="t_date">Transaction Date</label>
                            <input type="date" id="t_date" className="form-control" name="t_date" required />
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="t_amount_local">Transaction Amount</label>
                            <input type="text" id="t_amount_local" className="form-control" name="t_amount_local" required />
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="t_dest_walletID">Destination Wallet ID</label>
                            <input type="text" id="t_dest_walletID" className="form-control" name="t_dest_walletID" required />
                        </div>
                        <br />
                        <div className="row" />
                        <button className="btn btn-primary" onClick={this.handleSubmit}  type="submit" >Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(Enairatransanction);