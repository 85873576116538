import React, { useState } from "react";
import { useEffect } from "react";
import PageTitle from "../common/breadcrumb";
import ReportTable from "../common/table";
import Loader from "../util/loader";
import { serverLink } from "../../resources/url";
import axios from 'axios'
import { showAlert } from "../util/generalfunction";
import { formatDateAndTime } from "../util/dateformat";
import { Link } from "react-router-dom";

const KYCList=(props)=>{
    const [isLoading, setIsloading] = useState(true);
    const columns = ["sn", "Account Number", "Account Name", "Opening Date", "Account Type", "Account Currency","Current Balance","Account Tier","Action"]
    const [data, setData] = useState([])
    const [customerList, setCustomerList] = useState([])


    const getCustomers=async(bank_id='')=>{
        try {
            await axios.get(`${serverLink}kyc/customer-list`).then((result)=>{
                let response = result.data.recordset;
                console.log(result)
                let rows =[];
                if(response.length > 0){
                    setCustomerList(response);
                   response.map((item, index)=>{
                    rows.push([
                        index+1,
                        item.account_number,
                        item.account_name,
                        formatDateAndTime(item.account_opened_date,"date"),
                        item.account_type === "E" ? "Individual":"Corporate",
                        item.account_currency_code,
                        item.account_balance+".00",
                        (<span className={item.issues === "TIER 1" ? "badge badge-primary": item.issues === "TIER 2" ? "badge badge-secondary":"badge badge-success"}>
                           {item.issues}
                        </span>),
                        (<Link className="btn btn-sm btn-primary" to={`/kyc/customer-details/${item.account_number}`} >
                            <i className="fa fa-eye" />
                        </Link>)

                    ])
                   })
                   setData(rows);
                }
                setIsloading(false);
            })
        } catch (e) {
            showAlert("ERROR", "Network error, please check your connection", "error")
        }

    }

    useEffect(()=>{
        getCustomers();
    }, [])

return isLoading ? (<Loader/>) : (
    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
    <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                    <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
                        <div className="card mb-5">
                            <div className="card-header pt-5 pb-5">
                                <PageTitle title={["KYC", "KYC", "Customers list"]} />
                            </div>
                            <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                <div className="flex-grow-1">

                                    <div className="d-flex flex-wrap flex-stack">
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            <div className="d-flex flex-wrap">
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M2 21V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H3C2.4 22 2 21.6 2 21Z" fill="currentColor"></path>
                                                                <path d="M2 10V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H3C2.4 11 2 10.6 2 10Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1} style={{ fontSize: '40px' }}>{customerList.length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">Total Customers</div>
                                                </div>
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor"></rect>
                                                                <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={75} data-kt-initialized={1}>
                                                            {customerList.filter(x=>x.issues === "TIER 1").length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">TIER 1 Customers</div>
                                                </div>
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor"></rect>
                                                                <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={60} data-kt-countup-prefix="" data-kt-initialized={1}>
                                                            {customerList.filter(x=>x.issues === "TIER 2").length}
                                                            </div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">TIER 2 Customers</div>
                                                </div>
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor"></rect>
                                                                <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={60} data-kt-countup-prefix="" data-kt-initialized={1}>
                                                            {customerList.filter(x=>x.issues === "TIER 3").length}
                                                            </div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">TIER 3 Customers</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        </div>

                        {
                            customerList.length > 0 &&
                            <ReportTable columns={columns} data={data} height="700px" />
                        }

                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
)
}

export default KYCList;