import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setDashBoardData,
  setLoginDetails,
  setPermissionDetails,
} from "../../actions/softamlactions";
import Loader from "../util/loader";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { currencyConverter } from "../util/generalfunction";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import {
  str_by_months_char_data,
  str_by_year_chart_data,
  watchListChart,
} from "./chart-data";
// import {dateFormat} from '../util/dateformat'

const Dashboard = (props) => {
  const DashBoardData = props.DashBoardData;
  const [isLoading, setIsLoading] = useState(true);

  const [strData, setstrData] = useState(
    DashBoardData.length > 0 ? DashBoardData[0]._str_ : []
  );
  const [watchListData, setWatchListData] = useState(
    DashBoardData.length > 0 ? DashBoardData[0].watchlist_ : []
  );
  const [reportsData, setReportsData] = useState(
    DashBoardData.length > 0 ? DashBoardData[0].report__ : []
  );
  const [total_, setTotal] = useState(
    DashBoardData.length > 0 ? DashBoardData[0].total_ : []
  );
  const [topFourStr, setTopFourSTR] = useState(
    DashBoardData.length > 0 ? DashBoardData[0].top_four_str : []
  );
  const [StrByMonthChartData, setStrByMonthChartData] = useState(
    DashBoardData.length > 0 ? DashBoardData[0].str_history_by_months : []
  );
  const [cahrt_1_data, setChart1Data] = useState(
    DashBoardData.length > 0
      ? [str_by_months_char_data(DashBoardData[0].str_history_by_months)]
      : []
  );

  const [strbyYear, setStrByYear] = useState(
    DashBoardData.length > 0
      ? [str_by_year_chart_data(DashBoardData[0].str_history_by_year)]
      : []
  );
  const [watchListBySource, setWatchlistBySource] = useState(
    DashBoardData.length > 0
      ? [watchListChart(DashBoardData[0].watchlist_by_src)]
      : []
  );

  const [data, setData] = useState({
    total_str:
      DashBoardData.length > 0
        ? DashBoardData[0]._str_
            .map((x, y) => x.Transactions_Involved)
            .reduce((a, b) => a + b, 0)
        : 0,
    total_acc_involved:
      DashBoardData.length > 0
        ? DashBoardData[0]._str_
            .map((x, y) => x.accounts_involved)
            .reduce((a, b) => a + b, 0)
        : 0,
    total_watchlist:
      DashBoardData.length > 0
        ? DashBoardData[0].watchlist_
            .map((x, y) => x.total)
            .reduce((a, b) => a + b, 0)
        : 0,
    generated_reports:
      DashBoardData.length > 0 ? DashBoardData[0].report__.length : 0,
    total_str_amount:
      DashBoardData.length > 0 ? DashBoardData[0].total_amount_in_str : 0,
    str_history_by_months:
      DashBoardData.length > 0 ? DashBoardData[0].str_history_by_months : [],
    chart_1: [],
  });
  const [tdd, setT] = useState(0);
  const user = props.loginDetails;

  useEffect(() => {}, []);

  const get_str_reports = async () => {
    try {
      let total_t_involved;
      let total_acc_involved;
      let _str_ = [];

      await axios.get(`${serverLink}dashboard/str_report`).then((result) => {
        const response = result.data.recordset;
        const t_involved = response.map((x, y) =>
          parseInt(x.transactions_involved)
        );
        const acc_involved = response.map((x, y) =>
          parseInt(x.accounts_involved)
        );
        setTimeout(() => {
          total_t_involved = t_involved.reduce((a, b) => a + b, 0);
          total_acc_involved = acc_involved.reduce((a, b) => a + b, 0);
        }, 200);
        _str_ = response;
        setstrData(response);
      });

      let sum_;
      let watchlist_ = [];
      await axios.get(`${serverLink}dashboard/watch_list`).then((result) => {
        const response = result.data.recordset;
        const t_watchlist = response.map((x, y) => parseInt(x.total));
        sum_ = t_watchlist.reduce((a, b) => a + b, 0);
        watchlist_ = response;
        setWatchListData(response);
      });

      let report_;
      let report__ = [];
      await axios
        .get(`${serverLink}dashboard/generated_str_reports`)
        .then((result) => {
          const response = result.data.recordset;
          report_ = result.data.recordset;
          report__ = response;
          setReportsData(response);
        });

      let _total_ = [];
      await axios
        .get(`${serverLink}dashboard/total_amount_in_str`)
        .then((result) => {
          const response = result.data.recordset;
          _total_ = parseInt(response[0].totalamount);
          setTotal(parseInt(response[0].totalamount));
        });

      let top_four_str = [];
      await axios.get(`${serverLink}dashboard/top_four_str`).then((result) => {
        const response = result.data.recordset;
        top_four_str = response;
        setTopFourSTR(response);
      });

      let watchlist_by_ = [];
      await axios
        .get(`${serverLink}dashboard/watchlist_by_source`)
        .then((result) => {
          const response = result.data.recordset;
          watchlist_by_ = response;
          let d = watchListChart(response);
          setWatchlistBySource([d]);
        });

      let str_history_by_year = [];
      await axios
        .get(`${serverLink}dashboard/str_history_by_year`)
        .then((result) => {
          const response = result.data.recordset;
          str_history_by_year = response;
          //setStrByYear(response);
          let d = str_by_year_chart_data(response);
          setStrByYear([d]);
        });

      let str_history_by_months = [];
      await axios
        .get(`${serverLink}dashboard/str_history_by_months`)
        .then((result) => {
          const response = result.data.recordset;
          str_history_by_months = response;
          //setStrByMonthChartData(response);
          let d = str_by_months_char_data(response);
          setChart1Data([d]);
        });

      setTimeout(() => {
        setData({
          ...data,
          total_str: total_t_involved,
          total_acc_involved: total_acc_involved,
          total_str_amount: _total_,
          generated_reports: report_.length,
          total_watchlist: sum_,
          top_four_str: top_four_str,
          str_history_by_months: str_history_by_months,
          watchlist_by_src: watchlist_by_,
          str_history_by_year: str_history_by_year,
        });
      }, 400);

        setTimeout(() => {
            props.setonDashBoardData([
              {
                total_amount_in_str: _total_,
                _str_: _str_,
                watchlist_: watchlist_,
                report__: report__,
                total_: total_,
                top_four_str: top_four_str,
                str_history_by_months: str_history_by_months,
                str_history_by_year: str_history_by_year,
                watchlist_by_src: watchlist_by_,
              },
            ]);
        }, 400);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      console.log("NETWORK ERROR");
    }
  };

  useEffect(() => {
    get_str_reports();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-fluid"
      >
        <div className="row gy-5 g-xl-10">
          <div className="col-sm-6 col-xl-3 mb-xl-10">
            <div
              className="card h-lg-70"
              style={{
                backgroundImage: 'url("assets/media/patterns/bg-2.jpg")',
              }}
            >
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <span className="svg-icon svg-icon-2hx svg-icon-gray-400">
                    <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z"
                          fill="currentColor"
                        />
                        <path
                          d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span
                    style={{ color: "white" }}
                    className="fw-semibold fs-5x fw-bolder text-grey-800 lh-1 ls-n2"
                  >
                    {data.total_str}
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span
                    style={{ color: "white" }}
                    className="fw-bold fs-1x text-gray-1000"
                  >
                    Suspicious Transactions
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 mb-xl-10">
            <div className="card h-lg-70">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                    <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z"
                          fill="currentColor"
                        />
                        <path
                          d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span className="fw-semibold fs-4x fw-bolder text-grey-800 lh-1 ls-n2">
                    {data.total_acc_involved}
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span className="fw-bold fs-1x text-gray-1000">
                    Accounts Involved
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3 mb-xl-10">
            <div className="card h-lg-70">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span className="fw-semibold fs-4x fw-bolder text-grey-800 lh-1 ls-n2">
                    {data.total_watchlist}
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span className="fw-bold fs-1x text-gray-1000">
                    Total Watchlist
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 mb-xl-10">
            <div className="card h-lg-70">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <span className="svg-icon svg-icon-2hx svg-icon-gray-600">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z"
                        fill="currentColor"
                      />
                      <path
                        d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span className="fw-semibold fs-4x fw-bolder text-grey-800 lh-1 ls-n2">
                    {data.generated_reports}
                  </span>
                </div>
                <div className="d-flex align-items-start flex-column my-1">
                  <span className="fw-bold fs-1x text-gray-1000">
                    Generated Reports
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
            <div className="card card-flush h-lg-100">
              <div className="card-body pt-5">
                {cahrt_1_data.length > 0 && (
                  <div id="chart">
                    <ReactApexChart
                      options={cahrt_1_data[0].options}
                      series={cahrt_1_data[0].series}
                      type="line"
                      height={350}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card card-flush h-lg-100">
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <div className="d-flex align-items-center">
                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                    {currencyConverter(data.total_str_amount)}
                  </span>
                </div>
                <span className="text-gray-800 pt-1 fw-semibold fs-6">
                  Total Amount in Suspicious Transactions
                </span>
                <span className="text-gray-800 pt-5 fw-bolder fs-6 d-flex">
                  Top 10 recent STR transactions
                </span>
              </div>
            </div>
            <div className="card-body pt-5">
              <div className="d-flex flex-stack">
                <span className="fw-bold fs-6 me-2">Sender Wallet ID</span>
                <span className="fw-bold fs-6 me-2">Reciever Wallet ID</span>
                <span className="fw-bold fs-6 me-2">Transaction Amount</span>
                <span className="fw-bold fs-6 me-2">Transaction Date</span>
                <span
                  style={{ color: "white" }}
                  className="w-semibold fs-6 me-2"
                >
                  Transaction Date
                </span>
              </div>
              {topFourStr.length > 0 ? (
                <>
                  {topFourStr.map((x, y) => {
                    return (
                      <div key={y}>
                        <div className="d-flex flex-stack">
                          <span className="text-success fw-semibold fs-6 me-2">
                            {x.t_source_walletid}
                          </span>
                          <span className="text-success fw-semibold fs-6 me-2">
                            {x.t_dest_walletid}
                          </span>
                          <span className="text-primary fw-semibold fs-6 me-2">
                            {currencyConverter(x.t_amount_local)}
                          </span>
                          <span className="fw-semibold fs-6 me-2">
                            {x.t_date.split("T")[0]}
                          </span>
                          <button
                            type="button"
                            className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end"
                          >
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr095.svg*/}
                            <span className="svg-icon svg-icon-2">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.3"
                                  d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                                  fill="currentColor"
                                />
                                <rect
                                  x="21.9497"
                                  y="3.46448"
                                  width={13}
                                  height={2}
                                  rx={1}
                                  transform="rotate(135 21.9497 3.46448)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div className="separator separator-dashed my-3" />
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-xxl-12">
            <div className="card card-flush h-md-100">
              <div className="card-header pt-5">
                <div className="card-title d-flex flex-column">
                  <span className="text-gray-800 pt-1 fw-semibold fs-6">
                    Watchlist by Organizations
                  </span>
                </div>
              </div>
              <div className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0">
                <div className="row col-md-12">
                  <div className="col-md-12">
                    <div className="mb-10">
                      <div className="text-center">
                        {strbyYear.length > 0 && (
                          <Chart
                            options={watchListBySource[0].options}
                            series={watchListBySource[0].series}
                            type="bar"
                            width="100%"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-12">
            <div
              className="card card-flush h-md-100"
              style={{
                backgroundImage: 'url("assets/media/patterns/bg-2.jpg")',
              }}
            >
              <div className="card-header pt-5">
                <div className="card-title d-flex flex-column">
                  <span className="text-gray-200 pt-1 fw-semibold fs-6">
                    STR By Years
                  </span>
                </div>
              </div>
              <div className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0">
                <div className="row col-md-12">
                  <div className="col-md-12">
                    <div className="mb-10">
                      <div className="text-center">
                        {strbyYear.length > 0 && (
                          <ReactApexChart
                            options={strbyYear[0].options}
                            series={strbyYear[0].series}
                            type="radialBar"
                            height={390}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end::Content container*/}
    </div>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
    setonDashBoardData: (p) => {
      dispatch(setDashBoardData(p));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    WatchList: state.WatchList,
    StrList: state.StrList,
    Reports: state.Reports,
    DashBoardData: state.DashBoardData,
    AccountsInvolved: state.AccountsInvolved,
  };
};

export default connect(mapStateToProps, mapDisptachToProps)(Dashboard);
