import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { serverLink } from "../../resources/url";
import PageTitle from "../common/breadcrumb";
import Modal from "../common/modal";
import ReportTable from "../common/table";
import { formatDateAndTime } from "../util/dateformat";
import { currencyConverter, showAlert } from "../util/generalfunction";
import Loader from "../util/loader";

const NewCase = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormLoading0, setisFormLoading0] = useState('off')
    const [isFormLoading, setisFormLoading] = useState('off');
    const [isFormLoading2, setisFormLoading2] = useState('off');
    const [isFormLoading3, setisFormLoading3] = useState('off');
    const columns = ["sn", "Role ID", "Description", "Status", "Effective Date", "Inserted By", "action"];
    const [data, setData] = useState([]);
    const params = new URLSearchParams(window.location.search);

    const [transactionDet, setTransactionDet] = useState([]);
    const [createCase, setcreateCase] = useState({
        SN: "",
        TransNo: !params.get("tr") ? "" : params.get("tr"),
        CaseName: "",
        Category: "",
        Description: "",
        CurrentStatus: "",
        CurrentStatusDescription: "",
        ResolutionDescription: "",
        ResolutionStatus: "",
        CloseStatus: "",
        InsertedBy: props.loginDetails[0].UserName,
    })

    const [caseDetails, setCaseDetails] = useState([])
    const GetTransactionDetails = async (trans_no) => {
        setisFormLoading0('on')
        try {
            await axios.get(`${serverLink}case-management/transaction-details/${trans_no}`)
                .then((res) => {
                    setisFormLoading0('off')
                    let response = res.data.recordset;
                    if (response.length > 0) {
                        setTransactionDet(response);
                    }
                })
        } catch (e) {
            showAlert("ERROR", "Netwrok error, please check your connection", "error")
        }
    }

    const onEdit = (e) => {
        setcreateCase({
            ...createCase,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (createCase.CaseName === "" || createCase.Category === "" || createCase.Description === "") {
            showAlert("ERROR", "all fileds are required", "error")
            return;
        }
        setisFormLoading("on")
        try {
            await axios.post(`${serverLink}case-management/add-new-case`, createCase)
                .then((result) => {
                    if (result.data.message === "success") {
                        setisFormLoading("off");
                        //reset();
                        showAlert("SUCCESS", `case opened for ${createCase.TransNo}`, "success");
                    } else {
                        setisFormLoading("off")
                        showAlert("ERROR", "Netwrok error, please check your connection", "error")
                    }
                })

        } catch (e) {
            showAlert("ERROR", "Netwrok error, please check your connection", "error")
        }
    }

    const onSubmitCurrentStatus = async (e) => {
        e.preventDefault();
        if (createCase.CurrentStatus === "0" || createCase.CurrentStatusDescription === "") {
            showAlert("ERROR", "all fileds in current status are required", "error")
            return;
        }
        setisFormLoading2("on")
        try {
            await axios.put(`${serverLink}case-management/current-status/${createCase.TransNo}`, createCase)
                .then((result) => {
                    if (result.data.message === "success") {
                        getCaseDetails(createCase.TransNo);
                        setisFormLoading2("off");
                        reset();
                        showAlert("SUCCESS", `status for case ${createCase.TransNo} has been updated`, "success");
                    } else {
                        setisFormLoading2("off")
                        showAlert("ERROR", "Netwrok error, please check your connection", "error")
                    }
                })
        } catch (e) {
            console.log("network error")
        }
    }

    const onSubmitFinalStatus = async (e) => {
        e.preventDefault();
        if (createCase.CloseStatus === "0" || createCase.ResolutionStatus === "0" || createCase.ResolutionDescription === "") {
            showAlert("ERROR", "all fields in resolution section are required", "error")
            return;
        }
        setisFormLoading3("on")
        try {
            await axios.put(`${serverLink}case-management/final-status/${createCase.TransNo}`, createCase)
                .then((result) => {
                    if (result.data.message === "success") {
                        getCaseDetails(createCase.TransNo);
                        setisFormLoading3("off");
                        reset();
                        showAlert("SUCCESS", `case opened for ${createCase.TransNo}`, "success");
                    } else {
                        setisFormLoading3("off")
                        showAlert("ERROR", "Netwrok error, please check your connection", "error")
                    }
                })

        } catch (e) {
            showAlert("ERROR", "Netwrok error, please check your connection", "error")
        }
    }

    const getCaseDetails = async (tr_no) => {
        try {
            await axios.get(`${serverLink}case-management/case-details/${tr_no}`)
                .then((result) => {
                    let respose = result.data.recordset;
                    if (respose.length > 0) {
                        setCaseDetails(respose);
                        setcreateCase({
                            ...createCase,
                            CaseName: respose[0].CaseName,
                            Category: respose[0].Category,
                            Description: respose[0].Description,
                            CurrentStatus: respose[0].CurrentStatus,
                            CurrentStatusDescription: respose[0].StatusDescription === null ? "" : respose[0].StatusDescription,
                            ResolutionDescription: respose[0].ResolveComment === null ? "" : respose[0].ResolveComment,
                            ResolutionStatus: respose[0].ResolvedStatus,
                            CloseStatus: respose[0].CloseStatus,
                        })
                    }
                })
        } catch (e) {
            console.log(e)
            showAlert("ERROR", "Netwrok error, please check your connection", "error")
        }
    }

    useEffect(() => {
        if (params.get("tr")) {
            GetTransactionDetails(params.get("tr"));
            getCaseDetails(params.get("tr"));
        }

    }, [])

    const reset = () => {
        setcreateCase({
            ...createCase,
            SN: "",
            TransNo: "",
            CaseName: "",
            Category: "",
            Description: "",
            CurrentStatus: "",
            CurrentStatusDescription: "",
            ResolutionDescription: "",
            ResolutionStatus: "",
            CloseStatus: "",
        })
    }

    return isLoading ?
        (<Loader />) : (
            <>
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    <div className="d-flex flex-column flex-column-fluid">
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-fluid">
                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                    <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
                                        <div className="card card-flush h-lg-100">
                                            <div className="card-header pt-5">
                                                <PageTitle title={["Case Management", "case", "manage case"]} />
                                            </div>

                                            <div className="card-body pt-5">
                                                <div className="row g-9 mb-8">
                                                    <div className="col-md-8">
                                                        <div className="d-flex flex-column">
                                                            <label className="d-flex align-items-start fs-6 fw-bolder mb-2">Transaction number</label>
                                                            <input
                                                                disabled={!params.get("tr") ? false : true}
                                                                type="text" className="form-control" placeholder="transaction number" name="TransNo" onChange={onEdit} value={createCase.TransNo} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="d-flex flex-column mt-8">
                                                            <button type="submit" id="kt_modal_new_address_submit" onClick={() => GetTransactionDetails(createCase.TransNo)} data-kt-indicator={isFormLoading0} className="btn btn-primary w-150 mb-5">
                                                                <span className="indicator-label"> search transaction</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {
                                                        transactionDet.length > 0 &&
                                                        <>

                                                            <div className="row col-md-12 mt-10 text-start" style={{ fontSize: "16px" }}>
                                                                <div className="col-md-4">
                                                                    <div className="row mb-7">
                                                                        <label className="fw-semibold text-muted">
                                                                            <div>Source wallet id</div>
                                                                            <span className="fw-bold fs-6 text-gray-800">
                                                                                {transactionDet[0].t_source_walletID}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="row mb-7">
                                                                        <label className="fw-semibold text-muted">
                                                                            <div>Destination wallet id</div>
                                                                            <span className="fw-bold fs-6 text-gray-800">
                                                                                {transactionDet[0].t_dest_walletID}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="row mb-7">
                                                                        <label className="fw-semibold text-muted">
                                                                            <div>Transaction Amount</div>
                                                                            <span className="fw-bold fs-6 text-gray-800">
                                                                                {currencyConverter(transactionDet[0].t_amount_local)}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <div className="row mb-7">
                                                                        <label className="fw-semibold text-muted">
                                                                            <div>STR Code</div>
                                                                            <span className="fw-bold fs-6 text-gray-800">
                                                                                {transactionDet[0].str_code}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <div className="row mb-7">
                                                                        <label className="fw-semibold text-muted">
                                                                            <div>STR Reason</div>
                                                                            <span className="fw-bold fs-6 text-gray-800">
                                                                                {transactionDet[0].issues}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                            <div className="col-md-12">
                                                                <div className="d-flex flex-column">
                                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Case Name</label>
                                                                    <input
                                                                        disabled={!params.get("tr") ? false : true}
                                                                        type="test" className="form-control" name="CaseName" onChange={onEdit} value={createCase.CaseName} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="d-flex flex-column">
                                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Category</label>
                                                                    <select disabled={!params.get("tr") ? false : true}
                                                                        onChange={onEdit} value={createCase.Category} className="form-select form-select-solid" name="Category" >
                                                                        <option value="" >Select case category...</option>
                                                                        <option value={"1"}>Fraudulent</option>
                                                                        <option value={"2"}>Suspicious</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="d-flex flex-column">
                                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Description</label>
                                                                    <textarea rows={5} disabled={!params.get("tr") ? false : true}
                                                                        className="form-control" name="Description" value={createCase.Description} onChange={onEdit} />
                                                                </div>
                                                            </div>
                                                            {
                                                                !params.get("tr") &&
                                                                <div className="col-md-12 text-center">
                                                                    <button type="submit" id="kt_modal_new_address_submit" onClick={onSubmit} data-kt-indicator={isFormLoading} className="btn btn-primary w-150 mb-9 mt-9">
                                                                        <span className="indicator-label">Submit</span>
                                                                        <span className="indicator-progress">Please wait...
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                params.get("tr") &&
                                                                <div>
                                                                    <div className="col-md-12">
                                                                        <div className="d-flex flex-column">
                                                                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">CurrentStatus</label>
                                                                            <select onChange={onEdit} value={createCase.CurrentStatus} className="form-select" name="CurrentStatus" >
                                                                                <option value="" >current status...</option>
                                                                                <option value={"1"}>In-resolution</option>
                                                                                <option value={"2"}>Flagged</option>
                                                                                <option value={"3"}>Dismissed</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="d-flex flex-column">
                                                                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">CurrentStatus Comment</label>
                                                                            <textarea rows={5} className="form-control" name="CurrentStatusDescription" value={createCase.CurrentStatusDescription} onChange={onEdit} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center">
                                                                        <button type="submit" id="kt_modal_new_address_submit" onClick={onSubmitCurrentStatus} data-kt-indicator={isFormLoading2} className="btn btn-primary w-150 mb-9 mt-9">
                                                                            <span className="indicator-label">Submit</span>
                                                                            <span className="indicator-progress">Please wait...
                                                                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                                            </span>
                                                                        </button>

                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            }

                                                            {
                                                                params.get("tr") &&
                                                                <div className="col-md-12">
                                                                    <div className="d-flex flex-column">
                                                                        <div className="alert alert-dismissible bg-light-success border boder-dashed border-success d-flex flex-column p-5 mb-10">
                                                                            <div className="d-flex flex-column pe-0 pe-sm-10">
                                                                                <h5 className="mb-1">Case Resolution</h5>
                                                                                <span>Please provide resolution information and final status</span>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Resolution Status</label>
                                                                                    <select onChange={onEdit} value={createCase.ResolutionStatus} className="form-select bg-light-success" name="ResolutionStatus" >
                                                                                        <option value="" >resolution status...</option>
                                                                                        <option value={"1"}>Resolved</option>
                                                                                        <option value={"2"}>Not resolved</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Resolution Comment</label>
                                                                                    <textarea rows={5} className="form-control bg-light-success" name="ResolutionDescription" value={createCase.ResolutionDescription} onChange={onEdit} />
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="col-md-12">
                                                                                <div className="d-flex flex-column">
                                                                                    <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Close Status</label>
                                                                                    <select onChange={onEdit} value={createCase.CloseStatus} className="form-select bg-light-success" name="CloseStatus" >
                                                                                        <option value="" >close status...</option>
                                                                                        <option value={"1"}>case closed</option>
                                                                                        <option value={"2"}>Not closed</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12 text-center">
                                                                                <button type="submit" id="kt_modal_new_address_submit" onClick={onSubmitFinalStatus} data-kt-indicator={isFormLoading3} className="btn btn-primary w-150 mb-9 mt-9">
                                                                                    <span className="indicator-label">Submit</span>
                                                                                    <span className="indicator-progress">Please wait...
                                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                                                    </span>
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            }

                                                        </>
                                                    }

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(NewCase);

