import React from "react";
import { useState } from "react";
import ReportTable from "../../common/table";
import Modal from "../../common/modal";

const PrivlegeGroups = (props) => {
    const columns = ["SN", "Description", "Status", "Effective Date", "Inserted By"]


    return (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">User Groups</h3>
                </div>
                <button className="btn btn-primary align-self-center" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target" onClick={props.Reset}>Add Group</button>
            </div>
            <div className="card-body p-9">
                <div className="row mb-7">
                    <ReportTable columns={columns} data={props.GroupsData} />
                </div>
            </div>
            <Modal type="modal-md" title="Privilege Group" subtitle=" " id="kt_modal_new_target">
                <form id="kt_modal_new_target_form" >
                    <div className="row g-9 mb-8">
                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Group Name</label>
                            <input type="text" className="form-control"
                                placeholder="group name" name="PrivilegeDescription"
                                onChange={props.onEdit}
                                value={props.createGroup.PrivilegeDescription} />
                        </div>

                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Effective Date</label>
                            <input type="date" className="form-control"
                                placeholder="group name" name="EffectiveDate"
                                onChange={props.onEdit}
                                value={props.createGroup.EffectiveDate} />
                        </div>

                        <div className="d-flex flex-column">
                            <label className="d-flex align-items-center fs-6 fw-bolder mb-2">Account Status</label>
                            <div className="d-flex align-items-center">
                                <label className="form-check form-check-custom form-check-solid me-10">
                                    <input className="form-check-input h-20px w-20px" onChange={props.onEdit} type="radio" name="PrivilegeStatus" value="active"  />
                                    <span className="form-check-label fw-semibold">Active</span>
                                </label>
                                <label className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input h-20px w-20px" type="radio" onChange={props.onEdit} name="PrivilegeStatus" value="inactive"  />
                                    <span className="form-check-label fw-semibold">Inactive</span>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                        <button type="submit" id="kt_modal_new_address_submit" onClick={props.onGroupSubmit} data-kt-indicator={props.isFormLoading} className="btn btn-sm btn-dark fw-bold">
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default PrivlegeGroups;