import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import ReportTable from "../common/table.jsx";
import { formatDateAndTime } from "../util/dateformat.jsx";
import { showAlert, showConfirm } from "../util/generalfunction.jsx";
import Loader from "../util/loader";
import { serverLink } from "../../resources/url";

const Watchlist = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [generatedstrs, setgeneratedstrs] = useState([])
  const columns = ["SN", "RecordID" ,"Ind_name" ,"ind_AKA","City", "Stateprovince", "countrycode","datebirth", "source"];
  const [data, setData] = useState([]);
  const [strByWalletID, setstrByWalletID] = useState([]);

  const [formData, setFormData] = useState({
    Ind_name:""
  })

  const refreshList = () => {
    
    fetch(variables.API_URL + 'watchlist_summary')
    .then(response => response.json())
    .then(data => {
      setgeneratedstrs(data)
    });

  }

    const SaerchbyName =(e)=>{
      e.preventDefault();
      window.location.href = `/watchlistsearch?name=${formData.Ind_name}`;
     
  }


  useEffect(() => {
    refreshList();
  }, [])

  const onEdit=(e)=>{
    setFormData({
      ...formData, [e.target.id]: e.target.value
    })
  }

  return  (
    <div style={{width:'80%'}}>
      <div className="container-fluid">
        <div className="card ">
          <div className="card-header pt-7">
            <div className="card-title align-items-start flex-column">
              <h3> Sanction List</h3>
              <h2 id="strdesrp"></h2>
            </div>
          </div>
        </div>
      </div>

      <form >
        <div className="container-fluid">
        <div className="card ">
            <div className="card-body ">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-label-group">
                    <label className="lbltext" htmlFor="Reasonfordismissingstr">
                      Individual / Organisation Name</label>

                    <input
                      type="text"
                      id="Ind_name"
                      className="form-control"
                      placeholder="Enter Name"
                      autoFocus="autofocus" onChange={onEdit}
                    />
                  
                  </div>
                </div>
                <div className="col-md-3">
                <button
                      className="btn btn-primary mt-5" style={{width:'100%'}}
                      type="submit" onClick={SaerchbyName} >
                     Search by Name
                    </button>
                </div>
                
              </div>
              <br />
            </div>
          </div>
          <br />
        
        <div className="card">
         </div>
          
          <div className="card-body table-responsive">
                                        <table id="tbl1" className="table table-bordered table-striped  table-row-dashed align-start h5 gs-0 gy-3 my-0">

                                            <thead className="bg-dark text-white " >
                                                <tr>
                                                    <th className="p-0 pb-3  text-middle">SN.</th>
                                                    <th className="p-0 pb-3  text-start">Sanction Source</th>
                                                    <th className="p-0 pr-3  text-middle">Total Spool</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {generatedstrs.map((dep, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className="p-0 pb-3 text-start"><Link to={`/watchlistsearch?name=${dep.Source}`}>{dep.Source}</Link></td>
                                                        <td className="p-0 pr-3  text-middle">{dep.total}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

          </div>
         
      
          
        </div>
        <br />
      </form>
    </div>
  );

}
export default Watchlist;