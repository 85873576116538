const status = "Live";
export const serverLink =
    status === "Dev"
        ? "http://localhost:3005/"
        : "https://aml-server.onrender.com/";

export const projectName = "Anti Money Laundering Solution";
export const projectCode = "SOFTAML";
export const serverStatus = status;
export const projectURL = "https://wisdomictl.com/soft_aml";
export const projectPhone = "+23488114477";
export const projectEmail = "ahmadub81@gmail.com";
export const projectAddress ="Abuja, Nigeria";
export const projectFooter = "";
export const projectLogo = ``;

