import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { serverLink } from "../../resources/url";
import PageTitle from "../common/breadcrumb";
import ReportTable from "../common/table";
import { formatDateAndTime } from "../util/dateformat";
import Loader from "../util/loader";

const CaseLists = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const columns = ["CaseID", "Case Name", "Trans No", "Open Date", "Current Status", "Stage", "Closed?", "action"];
    const [data, setData] = useState([]);
    const [caseList, setcaseList] = useState([]);
    const params = new URLSearchParams(window.location.search);

    const getCaseLists = async (trans_no) => {
        try {
            await axios.get(`${serverLink}case-management/case-list`)
                .then((res) => {
                    let response = res.data.recordset;
                    if (response.length > 0) {
                        let rows = [];
                        response.map((item, index) => {
                            rows.push([
                                item.SN,
                                item.CaseName,
                                item.TransNo,
                                formatDateAndTime(item.OpenDate, "date"),
                                (<span className={item.CurrentStatus === "0" ? "badge badge-secondary" : "badge badge-success"}>{item.CurrentStatus === "0" ? "new" : "under review"}</span>),
                                item.Stage,
                                (<span className={item.CloseStatus === "0" ? "badge badge-primary" : "badge badge-success"}>{item.CloseStatus === "0" ? "not resolved" : "resolved"}</span>),
                                (
                                    <Link className="btn btn-sm btn-primary" to={`/cases/manage-case?tr=${item.TransNo}`}>
                                        <i className="fa fa-pen" />
                                    </Link>
                                )
                            ])
                        })
                        setcaseList(response);
                        setData(rows);
                    }
                    setIsLoading(false)
                })
        } catch (e) {
            console.log(e)
            console.log('NETWORK ERROR')

        }
    }

    useEffect(() => {
        getCaseLists();
    }, [])



    return isLoading ?
        (<Loader />) : (
            <>
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    <div className="d-flex flex-column flex-column-fluid">
                        <div id="kt_app_content" className="app-content flex-column-fluid">
                            <div id="kt_app_content_container" className="app-container container-fluid">
                                <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                    <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
                                        <div className="card mb-5">
                                            <div className="card-header pt-5 pb-5">
                                                <PageTitle title={["Case Management", "case", "case lists"]} />
                                            </div>
                                        </div>

                                        {
                                            caseList.length > 0 &&
                                            <ReportTable columns={columns} data={data} />
                                        }

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </>
        )
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(CaseLists);

