import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../login/login";
import Logout from "../login/logout";
import Header from "../Header/header";

class OuterRoutes extends Component {
    render() {
        return (
            <Routes>

                <Route exact path="/logout" element={<Logout />} />
                <Route exact path="/" element={<Login />} />

            </Routes>
        )

    }
}

export default OuterRoutes;