import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { serverLink } from "../../../resources/url";
import PageTitle from "../../common/breadcrumb";
import MainMenu from "./main-menu";
import { showAlert } from "../../util/generalfunction";
import { connect } from "react-redux";
import { formatDateAndTime } from "../../util/dateformat";
import Loader from "../../util/loader";
import SubMenu from "./sub-menu";
import SubSubMenu from "./sub-sub-menu";

const SidebarMenus = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormLoading, setisFormLoading] = useState('off');

    const [menuList, setMenuList] = useState([]);
    const [MainMenuList, setMainMenuList] = useState([]);
    const [subMenuList, setSubMenuList] = useState([]);
    const [SubMenuList, SetSubMenuList] = useState([]);
    const [subsubMenuList, setSubSubMenuList] = useState([]);
    const [SubSubMenuList, SetSubSubMenuList] = useState([])
    const [filteredSubMenu, setFilteredSubMenu] = useState([]);

    const [createMenu, setCreateMenu] = useState({
        MainMenuName: "",
        MainMenuID: "",
        SubMenuName: "",
        SubMenuID: "",
        SubMenuLink:"",
        SubSubMenuName: "",
        SubSubMenuID: "",
        SubSubMenuLink: "",
        Visibility: "",
        InsertedBy: props.loginDetails[0].UserName,

    })

    const getData = () => {
        try {
            axios.get(`${serverLink}user-management/main-menu-list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    setMainMenuList(response);
                    let rows = [];
                    response.map((item, index) => {
                        rows.push([
                            item.SN,
                            item.MenuName,
                            item.InsertedBy,
                            formatDateAndTime(item.InsertedDate, "date"),
                            <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_new_target"
                                onClick={() => {
                                    setCreateMenu({
                                        ...createMenu,
                                        MainMenuName: item.MenuName,
                                        MainMenuID: item.SN,
                                    })
                                }}
                            >
                                <i className="fa fa-pen" />
                            </button>
                        ])
                    })
                    setMenuList(rows)
                } else {
                    setMenuList([])
                }
            })
            axios.get(`${serverLink}user-management/sub-menu-list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    SetSubMenuList(response)
                    setFilteredSubMenu(response);
                    let rows = [];
                    response.map((item, index) => {
                        rows.push([
                            item.SN,
                            item.SubMenuName,
                            item.MainMenuID,
                            item.InsertedBy,
                            formatDateAndTime(item.InsertedDate, "date"),
                            <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#SubMenu"
                                onClick={() => {
                                    setCreateMenu({
                                        ...createMenu,
                                        SubMenuName: item.SubMenuName,
                                        MainMenuID: item.MainMenuID,
                                        SubMenuID: item.SN,
                                        SubMenuLink: item.SubMenuLink !== null ? item.SubMenuLink :""
                                    })
                                }}
                            >
                                <i className="fa fa-pen" />
                            </button>
                        ])
                    })
                    setSubMenuList(rows)
                } else {
                    setSubMenuList([])
                }
            })
            axios.get(`${serverLink}user-management/sub-sub-menu-list`).then((result) => {
                let response = result.data.recordset;
                if (response.length > 0) {
                    SetSubSubMenuList(response)
                    let rows = [];
                    response.map((item, index) => {
                        rows.push([
                            item.SN,
                            item.SubSubMenuName,
                            item.SubMenuID,
                            (<label className={item.Visibility.toString() === "1"? "badge badge-success":"badge badge-danger"}>
                                {item.Visibility.toString() === "1" ? "Visible":"Hidden"}
                            </label>),
                            item.SubSubMenuLink,
                            item.InsertedBy,
                            formatDateAndTime(item.InsertedDate, "date"),
                            <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#SubSubMenu"
                                onClick={() => {
                                    setCreateMenu({
                                        ...createMenu,
                                        MainMenuID: item.MainMenuID,
                                        SubMenuID: item.SubMenuID,
                                        SubSubMenuID: item.SN,
                                        SubSubMenuName: item.SubSubMenuName,
                                        SubSubMenuLink: item.SubSubMenuLink,
                                        Visibility: item.Visibility,
                                    })
                                }}
                            >
                                <i className="fa fa-pen" />
                            </button>
                        ])
                    })
                    setSubSubMenuList(rows)
                } else {
                    setSubSubMenuList([])
                }
            })
        } catch (e) {
            console.log('NETWROK ERROR')
        }
    }

    const onEdit = (e) => {
        setCreateMenu({
            ...createMenu,
            [e.target.name]: e.target.value
        })

        if (e.target.name === "MainMenuID") {
            let filter = SubMenuList.filter(x => x.MainMenuID.toString() === e.target.value.toString());
            setFilteredSubMenu(filter);
        }

    }
    const onMenuSubmit = async (e) => {
        e.preventDefault();
        try {
            if (createMenu.MainMenuName === "") {
                showAlert("Error", "Please enter main menu name", "error")
                return;
            }
            setisFormLoading('on')
            if(createMenu.MainMenuID === ""){
                await axios.post(`${serverLink}user-management/main-menu/add`, createMenu)
                .then((result => {
                    if (result.data.message === "success") {
                        document.getElementById("closeModal").click();
                        setisFormLoading('off')
                        getData();
                        showAlert("Success", "Menu created successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off')
                        showAlert("Error", "Please try again", "error")
                    }
                }))
            }else{
                await axios.put(`${serverLink}user-management/main-menu/update`, createMenu)
                .then((result => {
                    if (result.data.message === "success") {
                        setisFormLoading('off')
                        getData();
                        document.getElementById("closeModal").click();
                        showAlert("Success", "Menu updates successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off')
                        showAlert("Error", "Please try again", "error")
                    }
                }))
            }
            
        } catch (e) {
            console.log("Network error")
        }
    }

    const onSubMenuSubmit = async (e) => {
        e.preventDefault();
        try {
            if (createMenu.SubMenuName === "" || createMenu.MainMenuID === "") {
                showAlert("Error", "Please menu details", "error")
                return;
            }
            setisFormLoading('on')
            if(createMenu.SubMenuID === ""){
                await axios.post(`${serverLink}user-management/sub-menu/add`, createMenu)
                .then((result => {
                    if (result.data.message === "success") {
                        setisFormLoading('off')
                        document.getElementById("closeModal").click();
                        getData();
                        showAlert("Success", "submenu created successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off')
                        showAlert("Error", "Please try again", "error")
                    }
                }))
            }else{

                await axios.put(`${serverLink}user-management/sub-menu/update`, createMenu)
                .then((result => {
                    if (result.data.message === "success") {
                        setisFormLoading('off')
                        document.getElementById("closeModal").click();
                        getData();
                        showAlert("Success", "submenu updated successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off')
                        showAlert("Error", "Please try again", "error")
                    }
                }))
            }
            
        } catch (e) {
            console.log("Network error")
        }
    }

    const onSubSubMenuSubmit = async (e) => {
        e.preventDefault();
        try {
            if (createMenu.SubSubMenuName === "" || createMenu.SubSubMenuLink === "" || createMenu.Visibility === "") {
                showAlert("Error", "Please menu details", "error")
                return;
            }
            setisFormLoading('on')
            if(createMenu.SubSubMenuID === ""){
                await axios.post(`${serverLink}user-management/sub-sub-menu/add`, createMenu)
                .then((result => {
                    if (result.data.message === "success") {
                        setisFormLoading('off');
                        document.getElementById("closeModal").click();
                        getData();
                        showAlert("Success", "Menu created successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off')
                        showAlert("Error", "Please try again", "error")
                    }
                }))
            }else{
                await axios.put(`${serverLink}user-management/sub-sub-menu/update`, createMenu)
                .then((result => {
                    if (result.data.message === "success") {
                        setisFormLoading('off')
                        document.getElementById("closeModal").click();
                        getData();
                        showAlert("Success", "Menu created successfully", "success");
                        Reset();
                    } else {
                        setisFormLoading('off')
                        showAlert("Error", "Please try again", "error")
                    }
                }))
            }
           
        } catch (e) {
            console.log("Network error")
        }
    }
    const Reset = () => {
        setCreateMenu({
            ...createMenu,
            MainMenuName: "",
            MainMenuID: "",
            SubMenuName: "",
            SubMenuID: "",
            SUbSubMenuName: "",
            SubSUbMenuID: "",
            SubSubMenuLink: "",
        })
    }
    useEffect(() => {
        getData()
    }, [])


    return isLoading ? (<Loader />) : (
        <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card mb-5 mb-xl-10">

                        <div className="card-header pt-5">
                            <PageTitle title={["User management", "User privileges", "menus"]} />
                            <div className="card-toolbar">
                                <div className="text-center">
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                <div className="flex-grow-1">

                                    <div className="d-flex flex-wrap flex-stack">
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            <div className="d-flex flex-wrap">
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M2 21V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H3C2.4 22 2 21.6 2 21Z" fill="currentColor"></path>
                                                                <path d="M2 10V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H3C2.4 11 2 10.6 2 10Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1} style={{ fontSize: '40px' }}>{MainMenuList.length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">Main Menu</div>
                                                </div>
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor"></rect>
                                                                <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={75} data-kt-initialized={1}>{SubMenuList.length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">Sub-menu</div>
                                                </div>
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="8" y="9" width="3" height="10" rx="1.5" fill="currentColor"></rect>
                                                                <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="18" y="11" width="3" height="8" rx="1.5" fill="currentColor"></rect>
                                                                <rect x="3" y="13" width="3" height="6" rx="1.5" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={60} data-kt-countup-prefix="" data-kt-initialized={1}>{SubSubMenuList.length}</div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-400">Sub Sub-menu</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                <li className="nav-item mt-2">
                                    <a className="nav-link text-active-primary ms-0 me-10 py-5 active" data-bs-toggle="tab" href="#main-menu">Main Menu</a>
                                </li>
                                <li className="nav-item mt-2">
                                    <a className="nav-link text-active-primary ms-0 me-10 py-5" data-bs-toggle="tab" href="#sub-menu">Sub-Menu</a>
                                </li>
                                <li className="nav-item mt-2">
                                    <a className="nav-link text-active-primary ms-0 me-10 py-5" data-bs-toggle="tab" href="#sub-sub-menu">Sub Sub-Menu</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="tab-content" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: false}" data-kt-scroll-height="auto" data-kt-scroll-offset="70px">
                        <div className="tab-pane fade active show" id="main-menu" >
                            <MainMenu isFormLoading={isFormLoading} menuList={menuList} createMenu={createMenu} onMenuSubmit={onMenuSubmit} onEdit={onEdit} Reset={Reset} />
                        </div>
                        <div className="tab-pane fade" id="sub-menu" >
                            <SubMenu isFormLoading={isFormLoading} MainMenuList={MainMenuList} subMenuList={subMenuList} createMenu={createMenu} onEdit={onEdit} onSubMenuSubmit={onSubMenuSubmit} Reset={Reset} />
                        </div>
                        <div className="tab-pane fade" id="sub-sub-menu" >
                            <SubSubMenu isFormLoading={isFormLoading}  MainMenuList={MainMenuList} filteredSubMenu={filteredSubMenu}  subMenuList={subMenuList} subsubMenuList={subsubMenuList} createMenu={createMenu} onEdit={onEdit} Reset={Reset} onSubSubMenuSubmit={onSubSubMenuSubmit} SubMenuList={SubMenuList} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(SidebarMenus);
