import React from "react";

const PageTitle = (props) => {

    return (
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-start my-0">{props.title[0]}</h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                    <a href="#" className="text-gray-800 fw-bold fs-6">{props.title[1]}</a>
                </li>
                <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-muted">{props.title[2]}</li>
            </ul>
        </div>
    )
}

export default PageTitle;