import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import ReportTable from "../common/table.jsx";
import { formatDateAndTime } from "../util/dateformat.jsx";
import { showAlert, showConfirm } from "../util/generalfunction.jsx";

const Generatexmlreport = (props) => {
  const [generatedstrs, setgeneratedstrs] = useState([])
  const columns = ["SN", "Fine Name", "Date Generated"];
  const [data, setData] = useState([]);
  const [allSTRreport, setAllSTRreport] = useState([]);
  const [strByDate, setstrByDate] = useState([]);
  const [strByWalletID, setstrByWalletID] = useState([]);

  const [formData, setFormData] = useState({
    start: "",
    end: "",
    wallet_id: ""
  })

  const refreshList = () => {
    try {
      fetch(variables.API_URL + 'list_of_str_xml')
        .then(response => response.json())
        .then(data => {
          setgeneratedstrs(data)
          let rows = [];
          data.length > 0 &&
            data.map((x, y) => {
              rows.push([
                y + 1,
                (<a target={"_blank"} href={`${variables.API_URL}/download_STR?docFile=${x.XMLFileName}`}>{x.XMLFileName} </a>),
                formatDateAndTime(x.dateGenerated, "date")
              ])
            })
          setData(rows)
        });
    } catch (e) {
      showAlert('error', 'network error, try again', 'error')
    }

  }

  const generateAllXML = (e) => {
    e.preventDefault();
    try {
      axios.get(`${variables.API_URL}str_to_xml`).then((res) => {
        if (res.data.msg === "Generated Successfully") {
          setAllSTRreport([res.data])
          showAlert("SUCCESS", "report generated success, close to download", "success")
        }
        //this.setState({allSTR: res.data})
      })
    } catch (error) {
      showAlert('error', 'network error, try again', 'error')
    }

  }

  const generateXMLByDate = (e) => {
    e.preventDefault();
    try {
      axios.get(`${variables.API_URL}str_to_xml_date?start_date=${formData.start}&end_date=${formData.end}`)
        .then((res) => {
          if (res.data.msg === "Generated Successfully") {
            setstrByDate([res.data])
            showAlert("SUCCESS", "report generated success, close to download", "success")
          }
        })
    } catch (error) {
      showAlert('error', 'network error, try again', 'error')
    }
  }

  const generateXMLWalletID = (e) => {
    e.preventDefault();
    try {
      axios.get(`${variables.API_URL}str_to_xml_wallet_id?wallet_id=${formData.wallet_id}`)
        .then((res) => {
          if (res.data.msg === "Generated Successfully") {
            setstrByWalletID([res.data])
            showAlert("SUCCESS", "report generated success, close to download", "success")
          }
        })
    } catch (error) {
      showAlert('error', 'network error, try again', 'error')
    }
  }

  useEffect(() => {
    refreshList();
  }, [])

  const onEdit = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="card ">
          <div className="card-header pt-7">
            <div className="card-title align-items-start flex-column">
              <h3>Generate XML Report</h3>
              <h2 id="strdesrp"></h2>
            </div>
          </div>
        </div>
      </div>

      <form >
        <div className="container-fluid">
          <div className="card ">
            <div className="card-body ">
              <div className="row">
                <div className="col-md-3 ">
                  <div className="form-label-group">
                    <label
                      className="lbltext"
                      htmlFor="Reasonfordismissingstr"
                    >
                      Search with wallet ID
                    </label>
                    <input
                      type="text"
                      id="wallet_id"
                      className="form-control"
                      placeholder="Enter wallet ID"
                      autoFocus="autofocus" onChange={onEdit}
                    />
                    <button
                      className="btn btn-primary mt-5" style={{ width: '100%' }}
                      type="submit" onClick={generateXMLWalletID} >
                      Generate by WalletID

                    </button>
                    <div>
                      {
                        strByWalletID.length > 0 &&
                        <a href={strByWalletID[0].Link} target="_blank">Click to download Report</a>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <label className="lbltext" htmlFor="requestcommentfrom">
                          Start Date
                        </label>
                        <input
                          type="date"
                          id="start" onChange={onEdit}
                          className="form-control"
                          placeholder="Enter name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-label-group">
                        <label className="lbltext" htmlFor="requestcommentfrom">
                          End Date
                        </label>
                        <input
                          type="date"
                          id="end" onChange={onEdit}
                          className="form-control"
                          placeholder="Enter name"
                        />
                      </div>

                    </div>
                    <button
                      className="btn btn-primary btn-block mt-5 ms-4 me-4"
                      type="submit" onClick={generateXMLByDate} >
                      Generate XML by Date

                    </button>
                    <div>
                      {
                        strByDate.length > 0 &&
                        <a href={strByDate[0].Link} target="_blank">Click to download Report</a>
                      }
                    </div>

                  </div>
                </div>
                <div className="col-md-3">
                  <div style={{ paddingTop: '12px' }}>
                    <button
                      className="btn btn-info btn-primary mt-20"
                      type="submit"
                      onClick={generateAllXML} >
                      Generate All XML

                    </button>
                  </div>
                  <div>
                    {
                      allSTRreport.length > 0 &&
                      <a href={allSTRreport[0].Link} target="_blank">Click to download Report</a>
                    }
                  </div>

                </div>
              </div>
              <br />
            </div>
          </div>
          <br />

          <div className="card">
            <ReportTable columns={columns} data={data} />
          </div>
        </div>
        <br />
      </form>
    </div>
  );

}
export default Generatexmlreport;
