import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { variables } from '../Variables.jsx';
import ReportTable from "../common/table.jsx";
import { formatDateAndTime } from "../util/dateformat.jsx";
import { showAlert, showConfirm } from "../util/generalfunction.jsx";
import Loader from "../util/loader";

const Peplistinhouse = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [generatedstrs, setgeneratedstrs] = useState([])
  const columns = ["SN", "PEP Unique ID", "Full Name", "Gender", "PEP Classification", "PreviousvPosition", "Official Address","State of Origin"];
  const [data, setData] = useState([]);
  const [allSTRreport, setAllSTRreport] = useState([]);
  const [strByDate, setstrByDate] = useState([]);
  const [strByWalletID, setstrByWalletID] = useState([]);

  const [formData, setFormData] = useState({
    start:"",
    end:"",
    wallet_id:""
  })

  const refreshList = () => {
    fetch(variables.API_URL + 'Global_pepList')
      .then(response => response.json())
      .then(data => {
        setgeneratedstrs(data)
        let rows = [];
        data.length > 0 &&
          data.map((x, y) => {
            rows.push([
              y + 1,
              x.UniqueIdentifier,
              x.WatchName,
              x.Sex,
              x.PePClassification,
              x.PreviousPosition,
              x.OfficialAddress,
              x.StateOrigin
            ])
          })
        setData(rows)
        setIsLoading(false)
      });

  }


  useEffect(() => {
    refreshList();
  }, [])

  const onEdit=(e)=>{
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

  return isLoading ? (<Loader />) : (
    <div>
      <div className="container-fluid">
        <div className="card ">
          <div className="card-header pt-7">
            <div className="card-title align-items-start flex-column">
              <h3> Global Political Exposed Persons(PEPs)</h3>
              <h2 id="strdesrp"></h2>
            </div>
          </div>
        </div>
      </div>

      <form >
        <div className="container-fluid">
          <div className="card ">
            <div className="card-body ">
              <div className="row">
              <div className="col-md-9 ">
                </div>

                <div className="col-md-3 ">
                  <div className="form-label-group">
                
                    <button
                      className="btn btn-primary mt-5" style={{width:'100%'}}
                      type="submit"> Run Against Customer Info</button>
                  </div>
                </div>
             
              </div>
              <br />
            </div>
          </div>
          <br />

          <div className="card">
          <ReportTable columns={columns} data={data} />
          </div>
        </div>
        <br />
      </form>
    </div>
  );

}
export default Peplistinhouse;