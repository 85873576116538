import React, { Component } from 'react';
import axios from 'axios';
import {Link, Navigate} from 'react-router-dom';

class Reportingperson extends Component {

    state = {
        id: '',
        isLoading: false
    };



    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const token = localStorage.getItem('token');
        const url = 'https://gowtham-rest-api-crud.herokuapp.com/employees/'+ this.state.id;
        const name = document.getElementById('inputName').value;
        const phone = document.getElementById('inputPhone').value;
        const email = document.getElementById('inputEmail').value;
        const location = document.getElementById('inputLoca').value;
        const empid = document.getElementById('inputEmpId').value;
        const company = document.getElementById('inputComp').value;
        axios.put(url, { name: name, phone: phone, email:email, location:location, emp_id:empid, company:company, token:token })
            .then(result => {
                if (result.data.status) {
                    this.setState({ isLoading: false})
                }
            })
            .catch(error => {
                this.setState({ toDashboard: true });
                console.log(error);
            });
    };

   /*  renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/dashboard' />
        }
    }; */

    render() {
        const isLoading = this.state.isLoading;
        /* if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        } */

        return (
        <div>
               
                <div className="container-fluid">
                
                            <div class="card ">
                            <div className="card-header pt-7">
                            <div className="card-title align-items-start flex-column"> 
                                    <h3>REPORTING PERSON INFORMATION</h3>
                                    <h2 id="strdesrp" ></h2>   
                            </div>
                            </div> 
                 </div>  </div><br/>
                                
                          <form onSubmit={this.handleSubmit}>
                          <div className="container-fluid">
                              
                              <div className="card ">
                                  <div className="card-body ">

                                              <div className="row">
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="Title">Title:</label>
                                                       <input type="text" id="Title" className="form-control" placeholder="Enter Title" required="required" autoFocus="autofocus" />
                                                           </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="FirstName">First Name:</label>
                                                     <input type="text" id="FirstName" className="form-control" placeholder="Enter Phone" required="required" />
                                                       </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="MiddleName">Middle Name:</label>
                                                     <input type="text" id="MiddleName" className="form-control" placeholder="Enter Middle Name" required="required" />
                                                       </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="LastName">Last Name:</label>
                                                     <input type="text" id="LastName" className="form-control" placeholder="Enter Last Name" required="required" />
                                                       </div>
                                                  </div>
                                              </div><br/>
                                         
                                              <div className="row">
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="DateofBirth">Date of Birth:</label>
                                                      <input type="date" id="DateofBirth" className="form-control" placeholder=" Enter DateofBirth" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="PlaceofBirth">Place of Birth:</label>
                                                     <input type="text" id="PlaceofBirth" className="form-control" placeholder="Enter Place of Birth" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="MothersName">Mother's Name:</label>
                                                     <input type="text" id="MothersName" className="form-control" placeholder="Enter Mother's Name" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="PassportNumber">Passport Number:</label>
                                                     <input type="text" id="PassportNumber" className="form-control" placeholder="Enter Passport Number:" required="required" />
                                                         </div>
                                                  </div>
                                              </div><br/>

                                              <div className="row">
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext' htmlFor="StaffNumber">Staff Number:</label>
                                                   <input  type="text" id="StaffNumber" className="form-control" placeholder="Enter StaffNumber" required="required" />
                                                           </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="Teller">Telephone Number:</label>
                                                          <input type="text" id="TelephoneNumber" className="form-control" placeholder="Enter Telephone Number" required="required"/>     
                                                      </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="Extension">Extension:</label>
                                                          <input type="text" id="Extension" className="form-control" placeholder="Enter Extension" required="required"/>     
                                                      </div>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="Address">Address:</label>
                                                          <input type="text" id="Address" className="form-control" placeholder="Enter Address" required="required"/>     
                                                      </div>
                                                  </div>


                                              </div><br/>
                      
                                              <div className="row">
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext'  htmlFor="Town">Town</label>
                                                       <input type="text" id="Town" className="form-control" placeholder="Town" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="City">City:</label>
                                                          <input type="text" id="City" className="form-control" placeholder="Enter City" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="State">State</label>
                                                          <input type="text" id="State" className="form-control" placeholder="Enter State" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="Email">Email:</label>
                                                          <input type="text" id="Email" className="form-control" placeholder="Enter Email" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                              </div><br/>

                                              <div className="row">
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext'  htmlFor="Town">Occupation</label>
                                                       <input type="text" id="Occupation" className="form-control" placeholder="Occupation" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="MeansofIDNumber">Means of ID Number:</label>
                                                          <input type="text" id="MeansofIDNumber" className="form-control" placeholder="Enter Means of ID Number" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="Issued_by">Issued_by</label>
                                                          <input type="text" id="Issued_by" className="form-control" placeholder="" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="TaxNumber">Tax Number:</label>
                                                          <input type="text" id="TaxNumber" className="form-control" placeholder="Enter Tax Number" required="required"/>
                                                     </div>
                                                  </div>
                                              </div><br/>

                                              <div className="row">
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext'  htmlFor="Sourceofwealth">Source of wealth:</label>
                                                       <input type="text" id="Sourceofwealth" className="form-control" placeholder="Sourceofwealth" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="MeansofIDNumber">Means of ID Number:</label>
                                                          <input type="text" id="MeansofIDNumber" className="form-control" placeholder="Enter Means of ID Number" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="Comments">Comments:</label>
                                                          <input type="text" id="Comments" className="form-control" placeholder="" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="EmployerName">Employer's Name::</label>
                                                          <input type="text" id="EmployerName" className="form-control" placeholder="Enter Employer Name" required="required"/>
                                                     </div>
                                                  </div>
                                              </div><br/>
                                      
                                              <div className="row">
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                      <label className='lbltext'  htmlFor="EmployerTown">Employer's Town:</label>
                                                       <input type="text" id="EmployerTown" className="form-control" placeholder="" required="required" />
                                                         </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="EmployerAddress">Employer's Address:</label>
                                                          <input type="text" id="EmployerAddress" className="form-control" placeholder="Enter Means of ID Number" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="EmployerState">Employer's State:</label>
                                                          <input type="text" id="EmployerState" className="form-control" placeholder="" required="required"/>
                                                        
                                                      </div>
                                                  </div>

                                                  <div className="col-sm-3">
                                                      <div className="form-label-group">
                                                          <label className='lbltext'  htmlFor="EmployerNumber">Employer's Number::</label>
                                                          <input type="text" id="EmployerNumber" className="form-control" placeholder="Enter Employer Name" required="required"/>
                                                     </div>
                                                  </div>
                                              </div><br/>

                                  </div>
                              </div><br/>
                          
                                               
                          <div className="row">
                              <div className="col-md-6">
                                
                              <button className="btn btn-success btn-block" type="submit" disabled={this.state.isLoading ? true : false}>Save &nbsp;&nbsp;&nbsp;
                          {isLoading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                              <span></span>
                          )}
                        </button>
                              </div>
                              <div className="col-md-6">
                                 
                              <button className="btn btn-danger btn-block" type="submit" disabled={this.state.isLoading ? true : false}>Update Reporting Person &nbsp;&nbsp;&nbsp;
                          {isLoading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                              <span></span>
                          )}
                      </button>
                              </div>
                          </div><br/><br/>

                          </div>
                              </form>
                            
        </div>
        );
    }
}
export default Reportingperson;

